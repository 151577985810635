import { TaskRepository } from '@domain/models/task/gateway/task-repository';
import { GetAllTasksPaginatedUseCase } from '@domain/usecases/task/get-all-tasks-paginated-use-case';
import { GetAllTasksUseCase } from '@domain/usecases/task/get-all-tasks-use-case';
import { GetLastAddedTasksUseCase } from '@domain/usecases/task/get-last-added-tasks-use-case';
import { GetLegoUseCase } from '@domain/usecases/task/get-lego-use-case';
import { GetMostUsedTasksUseCase } from '@domain/usecases/task/get-most-used-tasks-use-case';
import { TaskRepositoryFakeImpl } from '@infra/task/task-repository-fake-impl';
import { TaskRepositoryImpl } from '@infra/task/task-repository-impl';
import { environment } from 'src/environments/environment';

const getTaskRepositoryProvider = {
  provide: TaskRepository,
  useClass: environment.useMocks ? TaskRepositoryFakeImpl : TaskRepositoryImpl
};

const getAllTasksUseCaseFactory = (taskRepository: TaskRepository) => {
  return new GetAllTasksUseCase(taskRepository);
};

const getAllTasksUseCaseProvider = {
  provide: GetAllTasksUseCase,
  useFactory: getAllTasksUseCaseFactory,
  deps: [TaskRepository]
};

const getAllTasksPaginatedUseCaseFactory = (taskRepository: TaskRepository) => {
  return new GetAllTasksPaginatedUseCase(taskRepository);
};

const getAllTasksPaginatedUseCaseProvider = {
  provide: GetAllTasksPaginatedUseCase,
  useFactory: getAllTasksPaginatedUseCaseFactory,
  deps: [TaskRepository]
};

const getLastAddedTasksUseCaseFactory = (taskRepository: TaskRepository) => {
  return new GetLastAddedTasksUseCase(taskRepository);
};

const getLastAddedTasksUseCaseProvider = {
  provide: GetLastAddedTasksUseCase,
  useFactory: getLastAddedTasksUseCaseFactory,
  deps: [TaskRepository]
};

const getLegoUseCaseFactory = (taskRepository: TaskRepository) => {
  return new GetLegoUseCase(taskRepository);
};

const getLegoUseCaseCaseProvider = {
  provide: GetLegoUseCase,
  useFactory: getLegoUseCaseFactory,
  deps: [TaskRepository]
};

const getMostUsedTasksUseCaseFactory = (taskRepository: TaskRepository) => {
  return new GetMostUsedTasksUseCase(taskRepository);
};

const getMostUsedTasksUseCaseProvider = {
  provide: GetMostUsedTasksUseCase,
  useFactory: getMostUsedTasksUseCaseFactory,
  deps: [TaskRepository]
};

export const getTaskDi = [
  getTaskRepositoryProvider,
  getAllTasksUseCaseProvider,
  getAllTasksPaginatedUseCaseProvider,
  getLastAddedTasksUseCaseProvider,
  getLegoUseCaseCaseProvider,
  getMostUsedTasksUseCaseProvider
];
