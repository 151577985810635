import { UseCase } from '@domain/base/use-case';
import { LegoRepository } from '@domain/models/lego/gateway/lego-repository';
import {
  LegoExecutionParams,
  LegoExecutionResponse
} from '@domain/models/lego/lego-execution';
import { Observable } from 'rxjs';

export class GetLegosExecutionUseCase
  implements UseCase<LegoExecutionParams, LegoExecutionResponse>
{
  constructor(private _legoRepository: LegoRepository) {}

  execute(
    legoExecutionParams: LegoExecutionParams
  ): Observable<LegoExecutionResponse> {
    return this._legoRepository.getLegosExecution(
      legoExecutionParams.limit,
      legoExecutionParams.offset,
      legoExecutionParams.stateExecution,
      legoExecutionParams.beginDate,
      legoExecutionParams.endDate
    );
  }
}
