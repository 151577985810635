import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
@Component({
  selector: 'app-task-list-loader',
  standalone: true,
  imports: [CommonModule, SkeletonModule],
  template: `
    <div class="flex flex-wrap mt-8 justify-content-around">
      <div *ngFor="let iter of arrNum" class="mb-8">
        <p-skeleton styleClass="mb-2" width="25rem"></p-skeleton>
        <p-skeleton styleClass="mb-4" width="25rem" height="4rem"></p-skeleton>
        <p-skeleton styleClass="mb-2" width="25rem"></p-skeleton>
      </div>
    </div>
  `,
  styles: []
})
export class TaskListLoaderComponent implements OnChanges {
  @Input() iterations = 8;
  protected arrNum = new Array<number>(this.iterations).fill(0);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iterations) {
      this.arrNum = new Array<number>(changes.iterations.currentValue).fill(0);
    }
  }
}
