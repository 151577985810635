import { Observable, delay, of } from 'rxjs';
import { ExecutionEventGateway } from '@domain/models/execution/gateway/execution-events';
import {
  EventResultType,
  EventTypes,
  ExecutionEvent
} from '@domain/models/execution/execution-event';

export class ExecutionEventGatewayFakeImpl extends ExecutionEventGateway {
  watchExecutionStatus(id: string): Observable<ExecutionEvent> {
    return new Observable<ExecutionEvent>((observer) => {
      const messages = [
        this.createStepCompletedEvent('lego-0-' + id),
        this.createMessageGeneratedEvent(),
        this.createStepCompletedEvent('lego-1-' + id),
        this.createMessageGeneratedEvent(),
        this.createMessageGeneratedEvent(),
        this.createStepCompletedEvent('lego-2-' + id),
        this.createMessageGeneratedEvent(),
        this.createStepCompletedEvent('lego-3-' + id),
        this.createTaskCompletedEvent()
      ];

      let multiplier = 1;
      for (const message of messages) {
        of(message)
          .pipe(delay(multiplier * 3000))
          .subscribe({
            next: (message) => observer.next(message)
          });

        multiplier++;
      }
    });
  }

  stopWatching(): void {
    console.log('Stop watching');
  }

  private createStepCompletedEvent(id: string): ExecutionEvent {
    return {
      id: 'random-id',
      type: EventTypes.STEP_COMPLETED,
      time: new Date().toISOString(),
      data: {
        taskId: id,
        message: `Lego ${id} completado`
      }
    };
  }

  private createMessageGeneratedEvent(): ExecutionEvent {
    return {
      id: 'random-id',
      type: EventTypes.MESSAGE_GENERATED,
      time: new Date().toISOString(),
      data: {
        message: 'Procesando...'
      }
    };
  }

  private createTaskCompletedEvent(): ExecutionEvent {
    return {
      id: 'random-id',
      type: EventTypes.TASK_COMPLETED,
      time: new Date().toISOString(),
      data: {
        message: 'Flujo completado exitosamente',
        results: [
          {
            type: EventResultType.DOWNLOAD,
            values: ['Descargar código generado', 'https://google.com']
          }
        ]
      }
    };
  }
}
