import {
  ExecutionEvent,
  StepCompletedEvent
} from '@domain/models/execution/execution-event';
import { HandleInterface } from './handle.interface';
import { StepperComponent } from '@view/executions/organisms/stepper/stepper.component';
import { ProgressArgs } from '../progress.component';

export class StepCompletedHandle implements HandleInterface {
  private stepKey = ProgressArgs.STEPPER;
  private terminalKey = ProgressArgs.TERMINAL;

  handle(event: ExecutionEvent, args: Map<ProgressArgs, any>): void {
    const stepper = args.get(this.stepKey) as StepperComponent;
    if (!stepper) {
      throw new Error('Output layer not found');
    }

    const data = event.data as StepCompletedEvent;
    stepper.markAsCompleted(data.taskId);

    const terminal = args.get(this.terminalKey);
    if (terminal && data.message) {
      terminal.add(data.message);
    }
  }
}
