import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import {
  Archetype,
  Lego,
  TaskState,
  TaskType,
  urlMapper
} from '@domain/models/task/task';
import { PaginationHelper } from '@infra/helpers/pagination';
import { TaskService } from '@domain/services/task.service';
import { PaginationComponent } from '@view/shared/molecules/pagination/pagination.component';
import { TaskListLoaderComponent } from '@view/tasks/molecules/task-list-loader/lego-list-loader.component';
import { TaskComponent } from '@view/tasks/molecules/task/task.component';
import { GetAllTasksPaginatedUseCase } from '@domain/usecases/task/get-all-tasks-paginated-use-case';
import { GetAllArchetypesPaginatedUseCase } from '@domain/usecases/archetype/get-all-archetypes-paginated-use-case';
import { HttpRouterClient } from '@infra/helpers/http-router-service';

@Component({
  selector: 'app-task-list-container[tasks]',
  standalone: true,
  imports: [
    CommonModule,
    TaskComponent,
    PaginationComponent,
    TaskListLoaderComponent
  ],
  providers: [PaginationHelper, TaskService],
  template: `
    <div class="flex flex-column justify-content-center">
      <app-task-list-loader *ngIf="loading"></app-task-list-loader>
      <div class="flex flex-wrap justify-content-center" *ngIf="!loading">
        <app-task
          *ngFor="let task of getTasks(); trackBy: getTaskId"
          [task]="task"
          class="w-25rem mx-3 my-3"
          (taskClicked)="taskSelected.emit($event)"
        ></app-task>
      </div>
      <app-pagination
        [rows]="taskPageSize"
        [totalRecords]="totalTaskNumber"
        [isEmptySearch]="isEmptySearch"
        (pageChange)="onPageChange($event)"
      ></app-pagination>
    </div>
  `,
  styles: []
})
export class TaskListContainerComponent implements OnInit, OnDestroy {
  @Input() set tasks(values: Lego[] | Archetype[]) {
    this.tasks$.next(values);
  }
  @Input() totalTaskNumber!: number;
  @Input() searchValue!: string;
  @Input() isEmptySearch!: { value: boolean };
  @Output() taskSelected = new EventEmitter<Lego>();
  protected typeTask: TaskType = '' as TaskType;
  private readonly appRouter = inject(HttpRouterClient);
  private readonly paginationHelper = inject(
    PaginationHelper<Lego | Archetype>
  );
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly taskService = inject(TaskService);
  private readonly getAllTasksPaginatedUseCase = inject(
    GetAllTasksPaginatedUseCase
  );
  private readonly getAllArchetypesPaginatedUseCase = inject(
    GetAllArchetypesPaginatedUseCase
  );

  private tasks$ = new Subject<Lego[] | Archetype[]>();
  private taskPageNumber = 1;
  protected taskPageSize = 12;
  protected tasksInPage = new Array<any>();
  protected loading = true;

  ngOnInit(): void {
    const rawUrl = this.appRouter.getCurrentUrl();
    const currentUrl = urlMapper[rawUrl];

    this.typeTask = currentUrl;

    if (!this.searchValue) {
      this.searchValue = '';
    }
    this.tasks$.pipe().subscribe((tasks) => {
      this.paginationHelper.setItems(tasks);
      this.tasksInPage = this.paginationHelper.getItems(
        this.taskPageNumber,
        this.taskPageSize,
        () => true
      );
      this.loading = false;
      this.taskService.activate(this.tasksInPage);
    });
  }

  ngOnDestroy(): void {
    this.taskService.deactivate();
  }

  onPageChange(event: any) {
    this.loading = true;
    this.taskPageNumber = Number(event.page) + 1;
    const offset = (this.taskPageNumber - 1) * this.taskPageSize;

    const fetchTasksMap: Partial<Record<TaskType, () => Observable<any>>> = {
      [TaskType.Lego]: () =>
        this.getAllTasksPaginatedUseCase.execute({
          state: TaskState.Active,
          limit: this.taskPageSize,
          offset,
          searchValue: this.searchValue
        }),
      [TaskType.Archetype]: () =>
        this.getAllArchetypesPaginatedUseCase.execute({
          limit: this.taskPageSize,
          offset,
          searchValue: this.searchValue
        })
    };

    const fetchTasks = fetchTasksMap[this.typeTask];

    if (fetchTasks) {
      fetchTasks().subscribe((response) => {
        this.tasksInPage = response.tasks;
        this.taskService.setTasks(this.tasksInPage);
        this.loading = false;
        this.changeDetector.detectChanges();
      });
    }
  }

  protected getTaskId(index: number, task: Lego | Archetype) {
    return task.id;
  }

  protected getTasks() {
    return this.taskService.getTasks();
  }

  getTaskPageNumber(): number {
    return this.taskPageNumber;
  }

  getLoading(): boolean {
    return this.loading;
  }

  getTaskPageSize(): number {
    return this.taskPageSize;
  }
}
