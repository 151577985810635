import { Observable } from 'rxjs';
import { ExecutionRequest, ExecutionType } from '../execution-request';
import { Execution } from '../execution';
import { ExecutionResponse } from '../execution-response';

export abstract class ExecutionRepository {
  abstract getInputs(
    ids: string[],
    type: ExecutionType
  ): Observable<ExecutionRequest>;

  abstract execute(execution: Execution): Observable<ExecutionResponse>;
}
