import {
  AfterViewChecked,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-terminal-org',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="rk-terminal-toolbar">
      <span>Eventos de la ejecución</span>
    </div>
    <div class="rk-terminal-screen" #scrollMe>
      <p *ngFor="let cmd of cmds; index as i" class="rk-terminal-cmd">
        <span class="rk-terminal-cursor">></span>
        {{ cmd }}
        <span
          [ngClass]="{ 'rk-terminal-cursor--hidden': i < cmds.length - 1 }"
          class="rk-terminal-cursor rk-terminal-cursor--blink"
          >_</span
        >
      </p>
    </div>
  `,
  styles: [
    `
      .rk-terminal-toolbar {
        width: 100%;
        box-sizing: border-box;
        height: 25px;
        background-color: #bbb;
        margin: 0 auto;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .rk-terminal-toolbar span {
        font-weight: bold;
        padding: 4px;
        display: inline-block;
      }

      .rk-terminal-screen {
        background-color: #151515;
        box-sizing: border-box;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        min-height: 200px;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .rk-terminal-cmd {
        text-align: left;
        font-size: 1.25em;
        font-family: monospace;
        white-space: normal;
        overflow: hidden;
        width: 0;
        color: #9cd9f0;
        margin: 0.5em;
        -webkit-animation: type 0.5s 1s steps(20, end) forwards;
        -moz-animation: type 0.5s 1s steps(20, end) forwards;
        -o-animation: type 0.5s 1s steps(20, end) forwards;
        animation: type 0.5s 1s steps(20, end) forwards;
      }

      .rk-terminal-cursor {
        color: #fff;
        font-weight: bold;
      }

      .rk-terminal-cursor--blink {
        -webkit-animation: blink 1s infinite;
        -moz-animation: blink 1s infinite;
        -o-animation: blink 1s infinite;
        animation: blink 1s infinite;
      }

      .rk-terminal-cursor--hidden {
        display: none;
      }

      @keyframes type {
        to {
          width: 100%;
        }
      }

      @keyframes blink {
        0% {
          opacity: 0;
        }
        40% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    `
  ]
})
export class TerminalOrgComponent implements AfterViewChecked {
  @ViewChild('scrollMe') private scrollContainer: ElementRef | undefined;

  protected cmds: string[] = [];

  ngAfterViewChecked() {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.scrollTop += 30;
    }
  }

  add(str: string) {
    this.cmds.push(str);
  }

  reset() {
    this.cmds = [];
  }
}
