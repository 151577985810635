import { inject } from '@angular/core';
import { TaskRepository } from '@domain/models/task/gateway/task-repository';
import {
  Lego,
  TaskResponse,
  TaskState,
  TopTask
} from '@domain/models/task/task';
import { AppHttpClient } from '@infra/helpers/http-client-service';
import { Observable } from 'rxjs';

export class TaskRepositoryImpl extends TaskRepository {
  private readonly httpClient = inject(AppHttpClient);

  getLastAdded(count: number): Observable<Lego[]> {
    const limitParam = count < 0 ? '?limit=1' : `?limit=${count}`;
    const offsetParam = `&offset=${0}`;
    const statusParam = `&state=${TaskState.Active}`;
    return this.httpClient.get<Lego[]>(
      `tasks${limitParam}${offsetParam}${statusParam}`
    );
  }

  getAll(state: TaskState): Observable<Lego[]> {
    const statusParam = state === TaskState.All ? '' : `?state=${state}`;

    return this.httpClient.get<Lego[]>(`tasks${statusParam}`);
  }

  getAllPaginated(
    limit: number,
    offset: number,
    state: TaskState,
    searchValue: string
  ): Observable<TaskResponse> {
    const limitParam = limit < 0 ? '?limit=1' : `?limit=${limit}`;
    const offsetParam = offset < 0 ? '' : `&offset=${offset}`;
    const statusParam = state === TaskState.All ? '' : `&state=${state}`;
    const searchParam = searchValue ? `&searchValue=${searchValue}` : '';

    return this.httpClient.get<TaskResponse>(
      `tasks${limitParam}${offsetParam}${statusParam}${searchParam}`
    );
  }

  getLego(legoId: string): Observable<Lego> {
    return this.httpClient.get<Lego>(`tasks/${legoId}`);
  }

  getMostUsedTasks(): Observable<TopTask[]> {
    return this.httpClient.get<TopTask[]>(`tasks/most-used`);
  }
}
