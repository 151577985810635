import {
  ExecutionEvent,
  ExecutionCompletedEvent
} from '@domain/models/execution/execution-event';
import { HandleInterface } from './handle.interface';
import { ProgressArgs } from '../progress.component';
import { ProgressOutputOrgComponent } from '@view/executions/organisms/progress-output/progress-output.component';
import { CartService } from '@domain/services/cart.service';
import { Lego } from '@domain/models/task/task';

export class ExecutionCompletedHandle implements HandleInterface {
  private outputKey = ProgressArgs.OUTPUT;
  private terminalKey = ProgressArgs.TERMINAL;

  handle(event: ExecutionEvent, args: Map<ProgressArgs, any>): void {
    const output = args.get(this.outputKey) as ProgressOutputOrgComponent;
    if (!output) {
      throw new Error('Output layer not found');
    }

    const executionCompleted = event.data as ExecutionCompletedEvent;
    const executionId = event.id;
    output.setResults(executionCompleted.results);
    output.setExecutionId(executionId);

    const terminal = args.get(this.terminalKey);
    if (terminal && executionCompleted.message) {
      terminal.add(executionCompleted.message);
    }

    const cartService = args.get(ProgressArgs.CART) as CartService<Lego>;
    if (cartService) {
      cartService.clearAllTasks();
    }
  }
}
