import { SidebarRepository } from '@domain/models/sidebar/gateway/sidebar-repository';
import { InactivateAllItemsUseCase } from '@domain/usecases/sidebar/inactivate-all-items-use-case';
import { OnStatusChangedUseCase } from '@domain/usecases/sidebar/on-status-changed-use-case';
import { SetStatusUseCase } from '@domain/usecases/sidebar/set-status-use-case';
import { SidebarServiceImpl } from '@infra/sidebar/sidebar-service';

const getSidebarRepositoryProvider = {
  provide: SidebarRepository,
  useClass: SidebarServiceImpl
};

const setStatusUseCaseFactory = (sidebarRepository: SidebarRepository) => {
  return new SetStatusUseCase(sidebarRepository);
};

const inactivateAllItemsUseCaseFactory = (
  sidebarRepository: SidebarRepository
) => {
  return new InactivateAllItemsUseCase(sidebarRepository);
};

const onStatusChangedUseCaseFactory = (
  sidebarRepository: SidebarRepository
) => {
  return new OnStatusChangedUseCase(sidebarRepository);
};

const setStatusUseCaseProvider = {
  provide: SetStatusUseCase,
  useFactory: setStatusUseCaseFactory,
  deps: [SidebarRepository]
};

const inactivateAllItemsUseCaseProvider = {
  provide: InactivateAllItemsUseCase,
  useFactory: inactivateAllItemsUseCaseFactory,
  deps: [SidebarRepository]
};

const onStatusChangedUseCaseProvider = {
  provide: OnStatusChangedUseCase,
  useFactory: onStatusChangedUseCaseFactory,
  deps: [SidebarRepository]
};

export const getSidebarDi = [
  getSidebarRepositoryProvider,
  setStatusUseCaseProvider,
  inactivateAllItemsUseCaseProvider,
  onStatusChangedUseCaseProvider
];
