import { Observable } from 'rxjs';
import { UseCase } from '@domain/base/use-case';
import { ExecutionRepository } from '@domain/models/execution/gateway/execution-repository';
import { Execution } from '@domain/models/execution/execution';
import { ExecutionResponse } from '@domain/models/execution/execution-response';

export class ExecutionRunnerUseCase
  implements UseCase<Execution, ExecutionResponse>
{
  constructor(private _executionRepository: ExecutionRepository) {}

  execute(execution: Execution): Observable<ExecutionResponse> {
    return this._executionRepository.execute(execution);
  }
}
