type Task = {
  id: string;
  name: string;
  description: string;
  type: TaskType;
  state: TaskState;
  tags: string;
  disabled: boolean;
  documentationURL: string;
  usefullinks: TaskLinks[];
  selected?: boolean;
};

type Lego = Task & {
  image: string;
  builderId?: string;
  builderName?: string;
  path?: string;
};

type Archetype = Task & {
  legos: LegoInfo[];
};

type LegoInfo = {
  id: string;
  name: string;
};

type TopTask = {
  id: string;
  count: number;
  name: string;
  category: string;
};

type TaskResponse = {
  tasks: Lego[];
  total: number;
};

type ArchetypeParams = {
  limit: number;
  offset: number;
  searchValue: string;
};

type ArchetypeResponse = {
  tasks: Archetype[];
  total: number;
};

type TaskOption = {
  icon: string;
  name: string;
  type: string;
};

type TaskParams = {
  limit: number;
  offset: number;
  state: TaskState;
  searchValue: string;
};

type TaskLinks = {
  url: string;
  name: string;
};

enum TaskState {
  All = '',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

enum TaskType {
  Workflow = 'WORKFLOW',
  Archetype = 'ARCHETYPE',
  Lego = 'LEGO'
}

class TaskFactory {
  public static create(
    id: string,
    name: string,
    description: string,
    image: string,
    type: TaskType,
    state: TaskState,
    tags: string,
    usefullinks: TaskLinks[],
    documentationURL: string,
    disabled = false
  ): Lego {
    return {
      id,
      name,
      description,
      image,
      type,
      state,
      tags,
      disabled,
      usefullinks,
      documentationURL
    };
  }
}

interface TaskItem {
  label: string;
  value: any;
  editable: boolean;
  key: string;
  hidden: boolean;
}

const urlMapper: { [key: string]: TaskType } = {
  '/legos': TaskType.Lego,
  '/archetypes': TaskType.Archetype
};

export {
  Task,
  Lego,
  Archetype,
  TaskResponse,
  ArchetypeResponse,
  TaskLinks,
  TaskOption,
  TaskParams,
  ArchetypeParams,
  TaskState,
  TaskType,
  TaskFactory,
  TaskItem,
  urlMapper,
  TopTask
};
