import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExecutionInputParam } from '@domain/models/execution/execution-request';
import { DynamicFormService } from './dynamic-form.service';
import { FormNumberItemaMolComponent } from '@view/shared/molecules/form-number-item/form-number-item.component';
import { FormTextItemMolComponent } from '@view/shared/molecules/form-text-item/form-text-item.component';
import { FormListItemMolComponent } from '@view/shared/molecules/form-list-item/form-list-item.component';
import { FormCheckItemMolComponent } from '@view/shared/molecules/form-check-item/form-check-item.component';

@Component({
  selector: 'app-dynamic-form-org',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormTextItemMolComponent,
    FormNumberItemaMolComponent,
    FormListItemMolComponent,
    FormCheckItemMolComponent
  ],
  template: `
    <form *ngIf="items.length > 0" [formGroup]="form">
      <div class="mb-3" *ngFor="let item of items">
        <div class="rk-dynamic-form-item" [ngSwitch]="item.type">
          <app-form-text-item-mol
            *ngSwitchCase="'TEXT'"
            class="flex"
            [parentForm]="form"
            [input]="item"
          ></app-form-text-item-mol>
          <app-form-number-item-mol
            *ngSwitchCase="'NUMBER'"
            class="flex"
            [parentForm]="form"
            [input]="item"
          ></app-form-number-item-mol>
          <app-form-list-item-mol
            *ngSwitchCase="'LIST'"
            class="flex"
            [parentForm]="form"
            [input]="item"
          ></app-form-list-item-mol>
          <app-form-check-item-mol
            *ngSwitchCase="'CHECK'"
            class="flex"
            [parentForm]="form"
            [input]="item"
          ></app-form-check-item-mol>
        </div>
      </div>
    </form>
  `,
  styles: []
})
export class DynamicFormOrgComponent implements OnChanges, OnDestroy {
  @Input() taskId = '';
  @Input() items: ExecutionInputParam[] = [];

  @Output() statusChange = new EventEmitter<FormStatus>();
  @Output() formSubmit = new EventEmitter<FormGroup>();

  protected readonly dynamicFormService = inject(DynamicFormService);
  protected form: FormGroup = new FormGroup({});
  private statusChangeSubscription?: Subscription;
  private destroy$ = new Subject<void>();
  private snapshots: Map<string, any> = new Map();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue.length) {
      const snapshot = this.snapshots.get(this.taskId) || {};

      this.form = this.dynamicFormService.toFormGroup(
        changes.items.currentValue,
        snapshot
      );

      this.configureFormStatusChange();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    this.snapshots.set(this.taskId, this.form.value);
    this.formSubmit.emit(this.form.value);
  }

  private configureFormStatusChange() {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }

    this.statusChange.emit(this.form.status as FormStatus);
    this.statusChangeSubscription = this.form.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.statusChange.emit(status as FormStatus);
      });
  }
}

export enum FormStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
}
