type Task = {
  id: string;
  name: string;
  description: string;
  image: string;
  type: TaskType;
  state: TaskState;
  tags: string[];
  disabled: boolean;
  usefullinks: TaskLinks[];
  documentationURL: string;
  builderId?: string;
  path?: string;
  selected?: boolean;
};

type TaskResponse = {
  tasks: Task[];
  total: number;
};

type TaskOption = {
  icon: string;
  name: string;
  type: string;
};

type TaskParams = {
  limit: number;
  offset: number;
  state: TaskState;
  searchValue: string;
};

type TaskLinks = {
  url: string;
  name: string;
};

enum TaskState {
  All = '',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

enum TaskType {
  Workflow = 'WORKFLOW',
  Lego = 'LEGO'
}

class TaskFactory {
  public static create(
    id: string,
    name: string,
    description: string,
    image: string,
    type: TaskType,
    state: TaskState,
    tags: string[],
    usefullinks: TaskLinks[],
    documentationURL: string,
    disabled = false
  ): Task {
    return {
      id,
      name,
      description,
      image,
      type,
      state,
      tags,
      disabled,
      usefullinks,
      documentationURL
    };
  }
}

interface TaskItem {
  label: string;
  value: string | boolean | string[] | TaskLinks[] | undefined;
  editable: boolean;
  key: string;
}

export {
  Task,
  TaskResponse,
  TaskLinks,
  TaskOption,
  TaskParams,
  TaskState,
  TaskType,
  TaskFactory,
  TaskItem
};
