import { Component, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsModule } from 'primeng/steps';
import { Subject } from 'rxjs';
import {
  ExecutionStepIndex,
  ExecutionStepItem,
  ExecutionStepPos
} from '@domain/models/execution/execution-step';

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [CommonModule, StepsModule],
  template: `
    <ul class="p-0 m-0 flex justify-content-start align-items-center">
      <li
        class="flex flex-auto flex-row-reverse align-items-center justify-content-end"
        [ngClass]="{
          'flex-grow-0': i === items.length - 1,
          'rk-stepper-step--active': i === activeIndex,
          'rk-stepper-step--past': i < activeIndex
        }"
        *ngFor="let item of items; index as i"
      >
        <span
          class="flex flex-auto flex-row align-items-center py-3 px-2 overflow-visible"
        >
          <span class="rk-stepper-step-number">{{ i + 1 }}</span>
          <span class="ml-2 white-space-nowrap">{{ item.label }}</span>
        </span>
      </li>
    </ul>
  `,
  styles: [
    `
      li::before {
        content: ' ';
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        width: 100%;
        display: block;
      }

      .rk-stepper-step-number {
        background-color: #9e9d9e;
        color: #ffffff;
        min-width: 1.714rem;
        height: 1.714rem;
        border-radius: 50%;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .rk-stepper-step--active {
        .rk-stepper-step-number {
          background-color: #007bff;
        }
      }

      .rk-stepper-step--past {
        .rk-stepper-step-number {
          background-color: green;
        }
      }
    `
  ]
})
export class StepperComponent {
  @Input() items: ExecutionStepItem[] = [];

  @Output() indexChange = new Subject<ExecutionStepIndex>();

  protected activeIndex = 0;

  next() {
    this.activeIndex += 1;
    if (this.canPropagateNext()) {
      this.propagateIndexChange();
    }
  }

  back() {
    if (!this.isFirstStep()) {
      this.activeIndex -= 1;
      this.propagateIndexChange();
    }
  }

  markAsCompleted(id: string) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index !== -1) {
      this.activeIndex = index + 1;
    }
  }

  isFirstStep(): boolean {
    return this.activeIndex === 0;
  }

  isLastStep(): boolean {
    return this.activeIndex === this.items.length - 1;
  }

  reset() {
    this.activeIndex = 0;
  }

  private propagateIndexChange() {
    this.indexChange.next({
      index: this.activeIndex,
      state: this.getStepPos()
    });
  }

  private getStepPos(): ExecutionStepPos {
    if (this.isFirstStep()) {
      return ExecutionStepPos.First;
    }
    if (this.isLastStep()) {
      return ExecutionStepPos.Last;
    }
    return ExecutionStepPos.Middle;
  }

  private canPropagateNext(): boolean {
    return this.activeIndex < this.items.length;
  }
}
