import { ExecutionEvent } from '@domain/models/execution/execution-event';
import { HandleInterface } from './handle.interface';
import { TerminalOrgComponent } from '@view/executions/organisms/terminal/terminal.component';
import { ProgressArgs } from '../progress.component';

export class MessageGenerateHandle implements HandleInterface {
  private terminalKey = ProgressArgs.TERMINAL;

  handle(event: ExecutionEvent, args: Map<ProgressArgs, any>): void {
    const terminal = args.get(this.terminalKey) as TerminalOrgComponent;
    if (!terminal) {
      throw new Error('Terminal not found');
    }

    terminal.add(event.data.message);
  }
}
