import { IdentityRepository } from '@domain/models/identity/gateway/identity-repository';
import { FullLogoutUseCase } from '@domain/usecases/identity/full-logout';
import { GetIdentityUserUseCase } from '@domain/usecases/identity/get-identity-user';
import { GetUserMenuUseCase } from '@domain/usecases/identity/get-user-menu';
import { IdentityServiceImpl } from '@infra/identity/identity-service';

const getIdentityRepositoryProvider = {
  provide: IdentityRepository,
  useClass: IdentityServiceImpl
};

const getIdentityUserUseCaseFactory = (
  identityRepository: IdentityRepository
) => {
  return new GetIdentityUserUseCase(identityRepository);
};

const getUserMenuUseCaseFactory = (identityRepository: IdentityRepository) => {
  return new GetUserMenuUseCase(identityRepository);
};

const fullLogoutUseCaseFactory = (identityRepository: IdentityRepository) => {
  return new FullLogoutUseCase(identityRepository);
};

const getIdentityUserUseCaseProvider = {
  provide: GetIdentityUserUseCase,
  useFactory: getIdentityUserUseCaseFactory,
  deps: [IdentityRepository]
};

const getUserMenuUseCaseProvider = {
  provide: GetUserMenuUseCase,
  useFactory: getUserMenuUseCaseFactory,
  deps: [IdentityRepository]
};

const fullLogoutUseCaseProvider = {
  provide: FullLogoutUseCase,
  useFactory: fullLogoutUseCaseFactory,
  deps: [IdentityRepository]
};

export const getIdentityDi = [
  getIdentityRepositoryProvider,
  getIdentityUserUseCaseProvider,
  getUserMenuUseCaseProvider,
  fullLogoutUseCaseProvider
];
