import { Injectable } from '@angular/core';
import { WizardComponent } from './wizard.component';
import { ExecutionContract } from '@domain/models/execution/execution';
import { Lego } from '@domain/models/task/task';

@Injectable({
  providedIn: 'root'
})
export class WizardVisualizerService {
  private wizardComponent?: WizardComponent;

  setComponent(previewComponent?: WizardComponent) {
    this.wizardComponent = previewComponent;
  }

  setTitle(title: string) {
    if (!this.wizardComponent) {
      return;
    }
    this.wizardComponent.setTitle(title);
  }

  open(contractReq: ExecutionContract, tasks: Lego[]) {
    if (!this.wizardComponent) {
      return;
    }
    this.wizardComponent.show(contractReq, tasks);
  }

  close() {
    if (!this.wizardComponent) {
      return;
    }
    this.wizardComponent.hide();
  }
}
