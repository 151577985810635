const LAST_TASKS_ADDED_NUMBER = 4;
const FIRST_TASKS_OFFSET_NUMBER = 0;

const TYPE_OPTIONS = [
  // { icon: 'pi pi-box', name: 'Todos', type: 'ALL' },
  // { icon: 'pi pi-cog', name: 'Workflows', type: 'WORKFLOW' },
  { icon: 'pi pi-folder', name: 'Legos', type: 'LEGO' }
];

const ALL_TYPE_OPTION = 'ALL';

const taskConstants = {
  LAST_TASKS_ADDED_NUMBER,
  FIRST_TASKS_OFFSET_NUMBER,
  TYPE_OPTIONS,
  ALL_TYPE_OPTION
};

export default taskConstants;
