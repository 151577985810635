import { UseCase } from '@domain/base/use-case';
import { LegoRepository } from '@domain/models/lego/gateway/lego-repository';
import { Observable } from 'rxjs';
import { Task } from '@domain/models/task/task';

export class CreateLegoUseCase implements UseCase<Task, void> {
  constructor(private _legoRepository: LegoRepository) {}

  execute(lego: Task): Observable<void> {
    return this._legoRepository.save(lego);
  }
}
