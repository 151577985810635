import { Injectable, inject } from '@angular/core';
import { ExecutionEvent } from '@domain/models/execution/execution-event';
import { ExecutionEventGateway } from '@domain/models/execution/gateway/execution-events';
import { WebSocketProviderService } from '@infra/helpers/web-socket-provider-service';
import { Observable, retry } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExecutionEventGatewayImpl extends ExecutionEventGateway {
  private socketFactory = inject(WebSocketProviderService);
  private connection$?: WebSocketSubject<ExecutionEvent>;

  watchExecutionStatus(id: string): Observable<ExecutionEvent> {
    this.stopWatching();
    this.connection$ = this.socketFactory.create<ExecutionEvent>(
      `${environment.socketUrl}/${id}`
    );
    return this.connection$.asObservable().pipe(retry(300));
  }

  stopWatching(): void {
    if (this.connection$) {
      this.socketFactory.close(this.connection$);
      this.connection$ = undefined;
    }
  }
}
