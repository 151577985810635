import { Injectable, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CartService } from './cart.service';
import { Archetype, Lego } from '@domain/models/task/task';

@Injectable()
export class TaskService<T extends Lego | Archetype> {
  private destroy$ = new Subject<void>();

  private readonly cartService = inject(CartService);
  private tasks: T[] = [];

  setTasks(tasks: T[]) {
    this.tasks = this.getTasksWithSelection(tasks);
  }

  activate(tasks: T[]) {
    this.restartActivation();
    this.tasks = this.getTasksWithSelection(tasks);

    this.cartService
      .listenCartState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tasks) => {
        if (tasks.length === 0) {
          this.restartSelection();
          return;
        }

        this.tasks = this.getTasksWithSelection(this.tasks);
      });

    this.cartService
      .whenCartChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe((cartAction) => {
        const { id, action } = cartAction;
        this.markTaskByAction(id, action);
      });
  }

  deactivate() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  restartActivation() {
    this.deactivate();
    this.destroy$ = new Subject<void>();
  }

  getTasks() {
    return this.tasks;
  }

  private markTaskByAction(taskId: string, action: string) {
    if (action === 'none') {
      return;
    }
    const taskIndex = this.tasks.findIndex((task) => task.id === taskId);

    if (taskIndex >= 0) {
      this.tasks[taskIndex].selected = action === 'add';
    }
  }

  private restartSelection() {
    this.tasks = this.tasks.map((task) => {
      return { ...task, selected: false };
    });
  }

  private getTasksWithSelection(tasks: T[]) {
    return tasks.map((task) => {
      const inCartIndex = this.cartService
        .getCart()
        .findIndex((cartTask) => cartTask.id === task.id);
      return { ...task, selected: inCartIndex >= 0 };
    });
  }
}
