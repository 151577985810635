import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemMolComponent } from '@view/shared/molecules/list-item/list-item.component';
import { EventResults } from '@domain/models/execution/execution-event';
import { GetResultsFileUseCase } from '@domain/usecases/execution/get-results-file';
import { switchMap, finalize } from 'rxjs';
import { DownloadGeneratedLegoUseCase } from '@domain/usecases/lego/download-generated-lego-use-case';
import { ProgressSpinnerComponent } from '@view/shared/atoms/loaders';
import { getExecutionDi } from 'src/app/config/di/exec-di';
import { getLegoDi } from 'src/app/config/di/lego-di';

@Component({
  selector: 'app-progress-output-org',
  standalone: true,
  imports: [CommonModule, ListItemMolComponent, ProgressSpinnerComponent],
  providers: [getExecutionDi, getLegoDi],
  template: `
    <h3>Resultados:</h3>
    <span
      *ngIf="hasError"
      class="rk-text--invalid"
      appTextContentAtm
      size="small"
      >{{ errorMessage }}</span
    >
    <ul class="list-none p-0 m-0">
      <li class="flex items-center mb-3" *ngFor="let result of results">
        <app-list-item-mol
          *ngIf="isDownloable(result)"
          [text]="downloadMessage"
          [documentationRedirect]="false"
          (click)="onDownload(id)"
          iconId="gift"
          [ngStyle]="{
            color: 'blue',
            cursor: 'pointer'
          }"
        ></app-list-item-mol>
        <ng-container *ngIf="downloading">
          <div class="flex items-center" [ngStyle]="{ marginLeft: '10px' }">
            <app-progress-spinner
              width="2rem"
              height="2rem"
            ></app-progress-spinner>
          </div>
        </ng-container>
      </li>
    </ul>
  `,
  styles: []
})
export class ProgressOutputOrgComponent {
  protected results: EventResults[] = [];
  protected id = '';
  private readonly getResultsFileUseCase = inject(GetResultsFileUseCase);
  private readonly downloadGeneratedLegoUseCase = inject(
    DownloadGeneratedLegoUseCase
  );
  private readonly errorMessageDownload = 'Error al descargar el archivo.';

  public downloading = false;
  public hasError = false;
  public errorMessage = '';
  public downloadMessage = 'Descargar plantilla';

  setResults(results: EventResults[]) {
    this.results = results;
  }

  setExecutionId(id: string) {
    this.id = id;
  }

  reset() {
    this.results = [];
    this.id = '';
  }

  onDownload(idExecution: string) {
    this.downloading = true;
    this.downloadGeneratedLegoUseCase
      .execute(idExecution)
      .pipe(
        switchMap((infoFile) => this.getResultsFileUseCase.execute(infoFile)),
        finalize(() => {
          this.downloading = false;
        })
      )
      .subscribe();
  }

  protected isDownloable(result: EventResults): boolean {
    return result.type === 'DOWNLOAD';
  }
}
