import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';

import { PreviewComponent } from '../preview/preview.component';
import { PreviewInputsViewModel } from './wizard.viewmodel';
import { ParameterizerComponent } from '../parameterizer/parameterizer.component';
import { ProgressComponent } from '../progress/progress.component';
import { GetExecutionContractUseCase } from '@domain/usecases/execution/get-execution-contract';
import { ExecutionBuilder } from '@domain/models/execution/execution-builder';
import {
  ExecutionContract,
  ExecutionTask
} from '@domain/models/execution/execution';
import { ExecutionRunnerUseCase } from '@domain/usecases/execution/run-execution';
import { Lego } from '@domain/models/task/task';
import { ExecutionContractService } from '@domain/models/execution/execution-contract-service';
import { WizardService } from '@domain/services/wizard.service';
import { ProgressSpinnerComponent } from '@view/shared/atoms/loaders';
import { TextTileAtmDirective } from '@view/shared/atoms/fields/text-title.directive';
import { AlertMessageService } from '@infra/helpers/alert-message-service';

@Component({
  selector: 'app-wizard',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    DialogModule,
    DividerModule,
    PreviewComponent,
    ParameterizerComponent,
    ProgressComponent,
    TextTileAtmDirective,
    ProgressSpinnerComponent
  ],
  template: `
    <p-dialog
      [(visible)]="visible"
      [modal]="true"
      [style]="{ width: '60vw' }"
      [draggable]="false"
      [resizable]="false"
      (onHide)="onHideDialog()"
    >
      <ng-template pTemplate="header">
        <div class="">
          <app-progress-spinner
            *ngIf="actionIsLoading"
            width="2rem"
            height="2rem"
          ></app-progress-spinner>
          <span class="ml-3" appTextTitleAtm>{{ title }}</span>
        </div>
      </ng-template>
      <p-divider styleClass="m-2"></p-divider>
      <ng-container [ngSwitch]="phase">
        <app-preview
          *ngSwitchCase="'preview'"
          (started)="onPreviewStarted($event)"
        ></app-preview>
        <app-parameterizer
          (completed)="onParameterizationCompleted($event)"
          *ngSwitchCase="'parameterizer'"
        ></app-parameterizer>
        <app-progress *ngSwitchCase="'progress'"></app-progress>
      </ng-container>
    </p-dialog>
  `,
  styles: []
})
export class WizardComponent implements OnInit, OnDestroy {
  private readonly wizardService = inject(WizardService);
  private readonly executionContractService = inject(ExecutionContractService);
  private readonly getContractUseCase = inject(GetExecutionContractUseCase);
  private readonly executionRunnerUseCase = inject(ExecutionRunnerUseCase);
  private readonly alertService = inject(AlertMessageService);
  private executionBuilder: ExecutionBuilder = new ExecutionBuilder();
  private destroy$ = new Subject<void>();

  protected phase = '';
  protected title = '';
  protected visible = false;
  protected actionIsLoading = false;

  ngOnInit(): void {
    this.wizardService
      .watchActionLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe((loading) => {
        this.actionIsLoading = loading;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setTitle(title: string) {
    this.title = title;
  }

  getTitle() {
    return this.title;
  }

  show(contractReq: ExecutionContract, tasks: Lego[]) {
    this.loadContract(contractReq);
    this.wizardService.setPreviewTasks(tasks);
    this.phase = 'preview';
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  onHideDialog() {
    this.phase = 'preview';
    this.wizardService.notifyRestartExecution();
    this.executionContractService.closeExecutionContract();
  }

  onPreviewStarted(inputs: PreviewInputsViewModel) {
    this.executionBuilder = new ExecutionBuilder();
    this.executionBuilder.withProject({
      projectName: inputs.projectName,
      applicationName: inputs.applicationName,
      apmId: inputs.apmId
    });
    this.wizardService.setPreviewTasks(inputs.tasks);
    this.phase = 'parameterizer';
  }

  onParameterizationCompleted(legos: ExecutionTask[]) {
    this.executionBuilder.withTasks(legos);
    const execution = this.executionBuilder.build();
    this.wizardService.notifyActionLoading();
    this.executionRunnerUseCase.execute(execution).subscribe(
      (executionRes) => {
        this.wizardService.setExecutionListener(executionRes);
        this.phase = 'progress';
        this.wizardService.notifyActionLoaded();
      },
      (error) => {
        this.wizardService.notifyRestartExecution();
        this.executionContractService.closeExecutionContract();
        const _messageError =
          error.error?.errors[0]?.message ||
          'Error intentando ejecutar legos. Por favor valide los parametros de entrada e intente nuevamnete.';
        this.alertService.showError(
          'Ejecución de legos fallida',
          _messageError
        );
        this.actionIsLoading = false;
        console.error(error);
      }
    );
  }

  private loadContract(contractReq: ExecutionContract) {
    const contract$ = this.getContractUseCase.execute(contractReq);
    this.executionContractService.setExecutionContract(contract$);
  }
}

export type RoutableModalView = {
  name: string;
  path: string;
};
