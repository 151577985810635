import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageModule } from 'primeng/image';
import { CartOrgComponent } from '../../../executions/organisms/cart/cart.component';
import { SearchComponent } from '@view/shared/atoms/search/search.component';
import { IdentityUser } from '@domain/models/identity/identity-user';
import { AdminToolbarComponent } from '@view/admin/organisms/admin-toolbar/admin-toolbar.component';
import { HttpRouterClient } from '@infra/helpers/http-router-service';
import { SetStatusUseCase } from '@domain/usecases/sidebar/set-status-use-case';
import { Subscription } from 'rxjs';
import { OnStatusChangedUseCase } from '@domain/usecases/sidebar/on-status-changed-use-case';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    ImageModule,
    SearchComponent,
    CartOrgComponent,
    AdminToolbarComponent
  ],
  template: `
    <div class="grid rk-toolbar">
      <div class="flex align-items-center pt-4">
        <button
          *ngIf="user"
          class="rk-sidebar-icon"
          (click)="changeSidebarStatus()"
          [ngClass]="{ 'header-menu-active': menuActive }"
        >
          <em class="pi pi-ellipsis-v"></em>
        </button>
        <p-image
          src="assets/images/logos/logotipo_transparent.svg"
          alt="Logotipo"
          height="70"
          width="250"
          class="logo-img"
        ></p-image>
        <p-image
          src="assets/images/vertical_line.png"
          height="60"
          width="3"
          class="ml-4 vertical-line"
        ></p-image>
        <a class="rk-headline--h4 rk-text-color--primary ml-7 company-name">
          <span>Rocket</span>
        </a>
      </div>
      <div class="flex align-items-center justify-content-end">
        <app-cart-org *ngIf="showCart()"></app-cart-org>
        <app-admin-toolbar [user]="user" class="ml-3"></app-admin-toolbar>
      </div>
    </div>
  `,
  styles: []
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() user?: IdentityUser;
  private readonly setStatusUseCase = inject(SetStatusUseCase);
  private readonly onStatusChangedUseCase = inject(OnStatusChangedUseCase);
  private readonly appRouter = inject(HttpRouterClient);
  private sidebarSuscription?: Subscription = undefined;

  menuActive = false;

  ngOnInit() {
    this.sidebarSuscription = this.onStatusChangedUseCase.execute(
      (status: boolean) => (this.menuActive = status)
    );
  }

  showCart() {
    return !this.appRouter.getCurrentUrl().includes('/admin') && !!this.user;
  }

  /**
   * Cambia el estado del sidebar, para que se muestre u oculte.
   */
  changeSidebarStatus(): void {
    this.menuActive = !this.menuActive;
    this.setStatusUseCase.execute(this.menuActive);
  }

  ngOnDestroy(): void {
    if (this.sidebarSuscription) {
      this.sidebarSuscription.unsubscribe();
    }
  }
}
