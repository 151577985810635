import { ArchetypeRepository } from '@domain/models/archetype/gateway/archetype-repository';
import { GetAllArchetypesPaginatedUseCase } from '@domain/usecases/archetype/get-all-archetypes-paginated-use-case';
import { ArchetypeRepositoryImpl } from '@infra/archetype/archetype-repository-impl';

const getArchetypeRepositoryProvider = {
  provide: ArchetypeRepository,
  useClass: ArchetypeRepositoryImpl
};

const getAllArchetypesPaginatedUseCaseFactory = (
  archetypeRepository: ArchetypeRepository
) => {
  return new GetAllArchetypesPaginatedUseCase(archetypeRepository);
};

const getAllArchetypesPaginatedUseCaseProvider = {
  provide: GetAllArchetypesPaginatedUseCase,
  useFactory: getAllArchetypesPaginatedUseCaseFactory,
  deps: [ArchetypeRepository]
};

export const getArchetypeDi = [
  getArchetypeRepositoryProvider,
  getAllArchetypesPaginatedUseCaseProvider
];
