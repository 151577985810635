import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import {
  LegoGeneratedParams,
  LegoGeneratedResponse
} from '@domain/models/lego/lego-generated';
import { LegoRepository } from '@domain/models/lego/gateway/lego-repository';

export class GetLegosGeneratedPaginatedUseCase
  implements UseCase<LegoGeneratedParams, LegoGeneratedResponse>
{
  constructor(private _legoRepository: LegoRepository) {}

  execute(
    legoGeneratedParams: LegoGeneratedParams
  ): Observable<LegoGeneratedResponse> {
    return this._legoRepository.getLegoGeneratedPaginated(
      legoGeneratedParams.limit,
      legoGeneratedParams.offset,
      legoGeneratedParams.searchValue
    );
  }
}
