import { ExecutionDownloaderProvider } from '@domain/models/execution/gateway/execution-downloader';
import { Observable } from 'rxjs';

/**
 * Implementación para la descarga de archivos de una ejecución
 */
export class ExecutionDownloaderProviderImpl extends ExecutionDownloaderProvider {
  /**
   * Descarga de archivos de una ejecución
   * @param event Evento con la información del archivo a descargar
   * @returns Observable con el resultado de la descarga
   */
  downloadFile(infoFile: string[]): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      try {
        const fileName = infoFile[0];
        const fileBytes = this.base64ToArrayBuffer(infoFile[1]);

        const filepath = window.URL.createObjectURL(
          new Blob([fileBytes], { type: 'application/octet-stream' })
        );

        const downloadLink = document.createElement('a');
        downloadLink.href = filepath;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(filepath);

        observer.next(true);
        observer.complete();
      } catch (error) {
        observer.error(false);
      }
    });
  }

  private base64ToArrayBuffer(base64: string): Uint8Array {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
}
