import { UseCase } from '@domain/base/use-case';
import { TaskRepository } from '@domain/models/task/gateway/task-repository';
import { Observable } from 'rxjs';
import { Lego } from '@domain/models/task/task';

export class GetLegoUseCase implements UseCase<string, Lego> {
  constructor(private _taskRepository: TaskRepository) {}

  public execute(legoId: string): Observable<Lego> {
    return this._taskRepository.getLego(legoId);
  }
}
