import { UseCase } from '@domain/base/use-case';
import { TaskRepository } from '@domain/models/task/gateway/task-repository';
import { Observable } from 'rxjs';
import { TopTask } from '@domain/models/task/task';

export class GetMostUsedTasksUseCase implements UseCase<void, TopTask[]> {
  constructor(private readonly _taskRepository: TaskRepository) {}

  public execute(): Observable<TopTask[]> {
    return this._taskRepository.getMostUsedTasks();
  }
}
