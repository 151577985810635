import { Lego, TaskType } from '../task/task';
import { ExecutionType } from './execution-request';

type Execution = {
  refId: string;
  project: ExecutionProject;
  tasks: ExecutionTask[];
};

type ExecutionProject = {
  projectName: string;
  applicationName: string;
  apmId: string;
};

type ExecutionTask = {
  id: string;
  inputParams: ExecutionInputParam[];
};

class ExecutionTaskFactory {
  static create(
    taskId: string,
    inputParams: ExecutionInputParam[]
  ): ExecutionTask {
    return { id: taskId, inputParams };
  }
}

type ExecutionInputParam = {
  id: string;
  value: string;
};

class ExecutionInputParamFactory {
  static fromObject(obj: any): ExecutionInputParam[] {
    return Object.keys(obj).map((key) => {
      return { id: key, value: obj[key] };
    });
  }
}

type ExecutionContract = {
  type: ExecutionType;
  ids: string[];
};

class ExecutionContractFactory {
  static create(tasks: Lego[]): ExecutionContract {
    const ids = tasks.map((task) => task.id);
    const isWorkflow = tasks.some((task) => task.type === TaskType.Workflow);

    return {
      type: isWorkflow ? ExecutionType.Workflow : ExecutionType.Lego,
      ids
    };
  }
}

export {
  Execution,
  ExecutionTask,
  ExecutionInputParam,
  ExecutionProject,
  ExecutionInputParamFactory,
  ExecutionTaskFactory,
  ExecutionContract,
  ExecutionContractFactory
};
