import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import {
  ExecutionStepIndex,
  ExecutionStepPos
} from '@domain/models/execution/execution-step';

@Component({
  selector: 'app-parameterizer-flow-control-mol',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  template: `
    <span *ngIf="isFirstStep()"></span>
    <p-button
      *ngIf="!isFirstStep()"
      icon="pi pi-check"
      class="rk-button--primary"
      (click)="onBackButtonClick()"
      [label]="getBackLabel()"
    ></p-button>
    <p-button
      icon="pi pi-check"
      [ngClass]="{
        'rk-button--primary': currentFormState === 'VALID'
      }"
      (click)="onNextButtonClick()"
      [label]="getNextLabel()"
      [disabled]="currentFormState === 'INVALID'"
    ></p-button>
  `,
  styles: []
})
export class ParameterizerFlowControlMolComponent {
  @Input() currentStepIndex: ExecutionStepIndex = {
    index: 0,
    state: ExecutionStepPos.First
  };
  @Input() currentFormState = 'INVALID';
  @Input() numberOfSteps = 0;

  @Output() next = new EventEmitter<FlowControlLabels>();
  @Output() back = new EventEmitter<FlowControlLabels>();

  protected currNextLabel: FlowControlLabels = FlowControlLabels.Next;
  protected currBackLabel: FlowControlLabels = FlowControlLabels.Back;

  onNextButtonClick() {
    this.currNextLabel = this.getNextLabel();
    this.next.emit(this.currNextLabel);
  }

  onBackButtonClick() {
    this.currBackLabel = this.getBackLabel();
    this.back.emit(this.currBackLabel);
  }

  protected getNextLabel(): FlowControlLabels {
    if (this.currentStepIndex.state === ExecutionStepPos.Last) {
      return FlowControlLabels.Finish;
    }

    return FlowControlLabels.Next;
  }

  protected getBackLabel(): FlowControlLabels {
    if (this.currentStepIndex.state === ExecutionStepPos.First) {
      return FlowControlLabels.None;
    }

    return FlowControlLabels.Back;
  }

  protected isFirstStep(): boolean {
    return (
      this.currentStepIndex.state === ExecutionStepPos.First ||
      this.numberOfSteps === 1
    );
  }

  protected isLastStep(): boolean {
    return (
      this.currentStepIndex.state === ExecutionStepPos.Last ||
      this.numberOfSteps === 1
    );
  }
}

export enum FlowControlLabels {
  Next = 'Siguiente',
  Back = 'Atrás',
  Finish = 'Generar',
  None = ''
}
