import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Lego } from '@domain/models/task/task';
import { ExecutionResponse } from '@domain/models/execution/execution-response';

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  private previewTaskListener = new BehaviorSubject<Lego[]>([]);
  private restartExecution$ = new Subject<boolean>();
  private executionListener: ExecutionResponse = {} as ExecutionResponse;
  private actionLoading$ = new BehaviorSubject<boolean>(false);

  setPreviewTasks(previewTasks: Lego[]) {
    this.previewTaskListener.next(
      previewTasks.sort((a, b) => a.name.length - b.name.length)
    );
  }

  getPreviewTasks() {
    return this.previewTaskListener.asObservable();
  }

  setExecutionListener(execution: ExecutionResponse) {
    this.executionListener = execution;
  }

  getExecutionListener() {
    return this.executionListener;
  }

  notifyRestartExecution() {
    this.restartExecution$.next(true);
  }

  watchRestartExecution() {
    return this.restartExecution$.asObservable();
  }

  notifyActionLoading() {
    this.actionLoading$.next(true);
  }

  notifyActionLoaded() {
    this.actionLoading$.next(false);
  }

  watchActionLoading() {
    return this.actionLoading$.asObservable();
  }
}
