import { Observable } from 'rxjs';
import { LegoGeneratedResponse } from '../lego-generated';
import { Task } from '@domain/models/task/task';

export abstract class LegoRepository {
  abstract getLegoGeneratedPaginated(
    limit: number,
    offset: number,
    searchValue: string
  ): Observable<LegoGeneratedResponse>;
  abstract downloadGeneratedLego(executionId: string): Observable<string[]>;
  abstract save(lego: Task): Observable<void>;
  abstract update(lego: Task): Observable<void>;
  abstract getLegosExecution(
    limit: number,
    offset: number,
    stateExecution: string,
    beginDate: string,
    endDate: string
  ): Observable<any>;
}
