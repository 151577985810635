import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { RouterModule } from '@angular/router';
import { IdentityUser } from '@domain/models/identity/identity-user';
import { FullLogoutUseCase } from '@domain/usecases/identity/full-logout';
import { getIdentityDi } from 'src/app/config/di/identity-di';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-toolbar',
  standalone: true,
  imports: [CommonModule, AvatarModule, RouterModule],
  providers: [getIdentityDi],
  template: `
    <div class="flex pt-3 mr-4">
      <button
        class="rk-toolbar-user"
        (click)="user ? toggleMenu() : fullLogout(urlLogout)"
      >
        <div class="rk-body--normal rk-text--capitalize">
          <span *ngIf="user; else notLoggedIn">{{ user.fullName }}</span>
          <ng-template #notLoggedIn>Cerrar Sesión</ng-template>
        </div>
        <img
          class="rk-toolbar-user-image"
          alt="user-icon"
          src="assets/images/user.png"
        />
        <em *ngIf="user" class="pi pi-angle-down"></em>
      </button>

      <ul
        *ngIf="user"
        class="rt-floatmenu-user"
        [ngClass]="{ 'profile-menu_active': userMenuActive }"
      >
        <li class="rt-floatmenu-user-access">
          <span
            >Último ingreso:
            {{ user.lastAccess | date: 'dd/MM/yyyy, h:mm a' }}</span
          >
        </li>
        <li class="rt-floatmenu-user-accions">
          <a
            href="https://micuenta.suranet.com/"
            rel="noopener"
            target="_blank"
          >
            <em class="pi pi-user mr-2"></em>
            <span>Mi cuenta</span>
          </a>
        </li>
        <li class="rt-floatmenu-user-accions">
          <a (click)="fullLogout(user.fullUrlLogout)">
            <em class="pi pi-power-off mr-2"></em>
            <span>Cerrar Sesion</span>
          </a>
        </li>
      </ul>
    </div>
  `,
  styles: []
})
export class AdminToolbarComponent {
  @Input() user?: IdentityUser;

  protected urlLogout = environment.logout;
  private readonly fullLogoutUseCase = inject(FullLogoutUseCase);

  userMenuActive = false;

  toggleMenu(): void {
    this.userMenuActive = !this.userMenuActive;
  }

  fullLogout(fullUrlLogout: string): void {
    this.fullLogoutUseCase.execute(fullUrlLogout);
    localStorage.clear();
  }
}
