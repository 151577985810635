import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { WatchExecutionStatusUseCase } from '@domain/usecases/execution/watch-execution-status';
import { WizardService } from '../../../../domain/services/wizard.service';
import { StepperComponent } from '@view/executions/organisms/stepper/stepper.component';
import { ExecutionRequest } from '@domain/models/execution/execution-request';
import { TerminalOrgComponent } from '@view/executions/organisms/terminal/terminal.component';
import { EventHandlerService } from './event-handler.service';
import { ProgressOutputOrgComponent } from '@view/executions/organisms/progress-output/progress-output.component';
import { CartService } from '@domain/services/cart.service';
import { ExecutionStepItem } from '@domain/models/execution/execution-step';
import { ExecutionContractService } from '@domain/models/execution/execution-contract-service';

@Component({
  selector: 'app-progress',
  standalone: true,
  imports: [
    CommonModule,
    StepperComponent,
    TerminalOrgComponent,
    ProgressOutputOrgComponent
  ],
  template: `
    <app-stepper #stepper [items]="steps"></app-stepper>
    <app-terminal-org #terminal></app-terminal-org>
    <app-progress-output-org #progress></app-progress-output-org>
  `,
  styles: []
})
export class ProgressComponent implements OnInit, OnDestroy {
  @ViewChild('terminal') terminal?: TerminalOrgComponent;
  @ViewChild('stepper') stepper?: StepperComponent;
  @ViewChild('progress') output?: ProgressOutputOrgComponent;

  private readonly wizardService = inject(WizardService);
  private readonly executionContractService = inject(ExecutionContractService);
  private readonly watchExecutionStatus = inject(WatchExecutionStatusUseCase);
  private readonly eventHandler = inject(EventHandlerService);
  private readonly cartServcie = inject(CartService);
  private destroy$ = new Subject<void>();

  protected steps: ExecutionStepItem[] = [];

  ngOnInit(): void {
    const executionListenerRef = this.wizardService.getExecutionListener();

    this.watchExecutionStatus
      .execute(executionListenerRef.listenerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((executionEvent) => {
        const args = new Map<ProgressArgs, any>();
        args.set(ProgressArgs.TERMINAL, this.terminal);
        args.set(ProgressArgs.STEPPER, this.stepper);
        args.set(ProgressArgs.OUTPUT, this.output);
        args.set(ProgressArgs.CART, this.cartServcie);
        this.eventHandler.handleEvent(executionEvent, args);
      });

    const contract$ = this.executionContractService.getExecutionContract();
    if (contract$) {
      contract$
        .pipe(takeUntil(this.destroy$))
        .subscribe((contract: ExecutionRequest) => {
          if (!contract.steps) return;

          this.steps = contract.steps.map((step) => {
            return {
              id: step.taskId,
              label: step.name,
              routerLink: '/home'
            };
          });
        });
    }

    this.wizardService.watchRestartExecution().subscribe((restart) => {
      if (restart) {
        this.dispose();
      }
    });
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  private dispose() {
    this.destroy$.next();
    this.destroy$.complete();

    this.terminal?.reset();
    this.stepper?.reset();
    this.output?.reset();
  }
}

export enum ProgressArgs {
  TERMINAL = 'TERMINAL',
  STEPPER = 'STEPPER',
  OUTPUT = 'OUTPUT',
  CART = 'CART'
}
