import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExecutionInputParam } from '@domain/models/execution/execution-request';
import { TooltipModule } from 'primeng/tooltip';
import { TextContentAtmDirective } from '@view/shared/atoms/fields/text-content.directive';
import { IconDirective } from '@view/shared/atoms/icons';

@Component({
  selector: 'app-form-check-item-mol',
  standalone: true,
  imports: [
    CommonModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    IconDirective,
    TextContentAtmDirective
  ],
  template: `
    <div class="rk-flex-0-1">
      <i
        appIcon
        class="flex-1"
        icon="question-circle"
        [pTooltip]="input.description"
      ></i>
    </div>
    <div class="rk-form-item align-items-start" [formGroup]="parentForm">
      <div class="flex">
        <input
          [id]="input.inputId"
          type="checkbox"
          class="p-1 w-full flex-1"
          pInputText
          [formControlName]="input.inputId"
        />
        <label for="input.inputId">
          {{ input.label }}
        </label>
      </div>
    </div>
  `,
  styles: [
    `
      .rk-form-item {
        flex: 3;
        display: flex;
        flex-direction: column;
        min-width: 300px;
        margin-left: 0.5rem;
      }
    `
  ]
})
export class FormCheckItemMolComponent {
  @Input() parentForm: FormGroup = {} as FormGroup;
  @Input() input: ExecutionInputParam = {} as ExecutionInputParam;
}
