<div class="wrapper mt-5">
  <div class="abs-center wd-xl">
    <!-- START panel-->
    <div class="text-center mb-xl">
      <div class="text-lg mb-lg rk-text-color--primary rk-text--bold">403</div>
      <p class="lead m0 rk-text--bold">Acceso denegado :(</p>
      <p>
        Lo sentimos! No tienes permisos para ver el recurso al que intentas
        acceder.
      </p>
    </div>
    <ul class="list-inline text-center text-sm mb-xl"></ul>
  </div>
</div>
