import { ExecutionRequest } from './execution-request';
import { ExecutionStepPos } from './execution-step';

const getCurrentStep = (contract: ExecutionRequest) => {
  const defaultStep = { index: 0, state: ExecutionStepPos.First };

  if (contract.steps.length === 1) {
    return { index: 0, state: ExecutionStepPos.Last };
  }

  return defaultStep;
};

export const ExecutionOps = {
  getCurrentStep
};
