import { environment } from 'src/environments/environment';
import { ExecutionRepository } from '@domain/models/execution/gateway/execution-repository';
import { ExecutionRepositoryFakeImpl } from '@infra/execution/execution-repository-fake-impl';
import { ExecutionRepositoryImpl } from '@infra/execution/execution-repository-impl';
import { ExecutionEventGateway } from '@domain/models/execution/gateway/execution-events';
import { ExecutionEventGatewayFakeImpl } from '@infra/execution/execution-event-gateway-fake-impl';
import { ExecutionEventGatewayImpl } from '@infra/execution/execution-event-gateway-impl';
import { GetExecutionContractUseCase } from '@domain/usecases/execution/get-execution-contract';
import { ExecutionRunnerUseCase } from '@domain/usecases/execution/run-execution';
import { WatchExecutionStatusUseCase } from '@domain/usecases/execution/watch-execution-status';
import { ExecutionDownloaderProvider } from '@domain/models/execution/gateway/execution-downloader';
import { GetResultsFileUseCase } from '@domain/usecases/execution/get-results-file';
import { ExecutionDownloaderProviderImpl } from '@infra/execution/execution-downloader-provider-impl';

const getExecutionRepositoryProvider = {
  provide: ExecutionRepository,
  useClass: environment.useMocks
    ? ExecutionRepositoryFakeImpl
    : ExecutionRepositoryImpl
};

const getExecutionEventGatewayProvider = {
  provide: ExecutionEventGateway,
  useClass: environment.useMocks
    ? ExecutionEventGatewayFakeImpl
    : ExecutionEventGatewayImpl
};

const getExecutionDownloaderProvider = {
  provide: ExecutionDownloaderProvider,
  useClass: ExecutionDownloaderProviderImpl
};

const getExecutionContractUseCaseFactory = (
  repository: ExecutionRepository
) => {
  return new GetExecutionContractUseCase(repository);
};

const getExecutionContractUseCaseProvider = {
  provide: GetExecutionContractUseCase,
  useFactory: getExecutionContractUseCaseFactory,
  deps: [ExecutionRepository]
};

const executionRunnerUseCaseFactory = (repository: ExecutionRepository) => {
  return new ExecutionRunnerUseCase(repository);
};

const executionRunnerUseCaseProvider = {
  provide: ExecutionRunnerUseCase,
  useFactory: executionRunnerUseCaseFactory,
  deps: [ExecutionRepository]
};

const watchExecutionStatusUseCaseFactory = (gateway: ExecutionEventGateway) => {
  return new WatchExecutionStatusUseCase(gateway);
};

const watchExecutionStatusUseCaseProvider = {
  provide: WatchExecutionStatusUseCase,
  useFactory: watchExecutionStatusUseCaseFactory,
  deps: [ExecutionEventGateway]
};

const getResultsFileUseCaseFactory = (
  provider: ExecutionDownloaderProvider
) => {
  return new GetResultsFileUseCase(provider);
};

const getResultsFileUseCaseProvider = {
  provide: GetResultsFileUseCase,
  useFactory: getResultsFileUseCaseFactory,
  deps: [ExecutionDownloaderProvider]
};

export const getExecutionDi = [
  getExecutionRepositoryProvider,
  getExecutionEventGatewayProvider,
  getExecutionDownloaderProvider,
  getExecutionContractUseCaseProvider,
  executionRunnerUseCaseProvider,
  watchExecutionStatusUseCaseProvider,
  getResultsFileUseCaseProvider
];
