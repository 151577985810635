import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Servicio para realizar simplificar la ejecución de solicitudes HTTP hacia una API
 */
@Injectable({
  providedIn: 'root'
})
export class AppHttpClient {
  /**
   * Servicio HttpClient de angular
   */
  private readonly httpClient = inject(HttpClient);

  /**
   * Opciones comunes para las solicitudes HTTP
   */
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**
   * Realiza una solicitud HTTP de tipo GET
   * @param url URL de la solicitud
   */
  get<T>(url: string): Observable<T> {
    return this.httpClient
      .get<T>(`${environment.baseUrl}/${url}`, this.httpOptions)
      .pipe(
        retry({ count: 1, delay: 200 }),
        map((response: any) => response.results)
      );
  }

  /**
   * Realiza una solicitud HTTP de tipo POST
   * @param url URL de la solicitud
   * @param body Cuerpo de la solicitud
   */
  post<T>(url: string, body: any): Observable<T> {
    const opts = {
      headers: this.httpOptions.headers,
      observe: 'response' as 'body'
    };
    return this.httpClient.post<T>(`${environment.baseUrl}/${url}`, body, opts);
  }

  /**
   * Realiza una solicitud HTTP de tipo PUT
   * @param url URL de la solicitud
   * @param body Cuerpo de la solicitud
   */
  put<T>(url: string, body: any): Observable<T> {
    return this.httpClient
      .put<T>(`${environment.baseUrl}/${url}`, body, this.httpOptions)
      .pipe(retry({ count: 1, delay: 200 }));
  }

  /**
   * Realiza una solicitud HTTP de tipo PUT
   * @param url URL de la solicitud
   */
  delete<T>(url: string): Observable<T> {
    return this.httpClient
      .delete<T>(`${environment.baseUrl}/${url}`, this.httpOptions)
      .pipe(retry({ count: 1, delay: 200 }));
  }
}
