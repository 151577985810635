type ExecutionResponse = {
  listenerId: string;
};

class ExecutionResponseFactory {
  public static createExecutionResponse(listenerId: string): ExecutionResponse {
    return { listenerId };
  }
}

export { ExecutionResponse, ExecutionResponseFactory };
