import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';

@Component({
  selector: 'app-pagination[totalRecords]',
  standalone: true,
  imports: [CommonModule, PaginatorModule],
  template: `
    <div class="card flex justify-content-center">
      <p-paginator
        (onPageChange)="onPageChange($event)"
        [first]="first"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
      ></p-paginator>
    </div>
  `,
  styles: []
})
export class PaginationComponent implements OnChanges {
  @Input() totalRecords = 0;
  @Input() first = 0;
  @Input() rows = 0;
  @Input() isEmptySearch!: { value: boolean };

  @Output() pageChange = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.isEmptySearch?.currentValue?.value === true ||
      changes.totalRecords?.currentValue
    ) {
      this.onPageChange({
        page: 0,
        first: 0,
        rows: this.rows,
        pageCount: this.getPageCount()
      });
    }
  }

  getPageCount() {
    return Math.ceil(this.totalRecords / this.rows);
  }

  onPageChange(event: any) {
    if (this.first !== event.first) {
      this.first = event.first;
      this.rows = event.rows;
      this.pageChange.emit(event);
    }
  }
}
