import { inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppHttpClient } from '@infra/helpers/http-client-service';
import {
  ExecutionListenerNotPresentError,
  ExecutionUnsoportedResponseError
} from '@domain/models/execution/errors';
import { Execution } from '@domain/models/execution/execution';
import {
  ExecutionType,
  ExecutionRequest
} from '@domain/models/execution/execution-request';
import {
  ExecutionResponse,
  ExecutionResponseFactory
} from '@domain/models/execution/execution-response';
import { ExecutionRepository } from '@domain/models/execution/gateway/execution-repository';

export class ExecutionRepositoryImpl extends ExecutionRepository {
  private readonly httpClient = inject(AppHttpClient);

  getInputs(ids: string[], type: ExecutionType): Observable<ExecutionRequest> {
    const encodedIds = encodeURI(ids.join(','));
    return this.httpClient.get<ExecutionRequest>(
      `tasks/inputs?type=${type}&ids=${encodedIds}`
    );
  }
  execute(execution: Execution): Observable<ExecutionResponse> {
    return this.httpClient.post<any>('tasks/execute', execution).pipe(
      map((response: any) => {
        if (response.status === 202) {
          const listenerId = response.headers.get('Location');
          if (!listenerId) {
            throw new ExecutionListenerNotPresentError();
          }

          return ExecutionResponseFactory.createExecutionResponse(listenerId);
        } else {
          throw new ExecutionUnsoportedResponseError();
        }
      })
    );
  }
}
