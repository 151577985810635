export type ExecutionEvent = {
  id: string;
  type: string;
  time: string;
  data: ExecutionDataEvent;
};

export type EventResults = {
  type: EventResultType;
  values: string[];
};

export enum EventResultType {
  DOWNLOAD = 'DOWNLOAD'
}

export enum EventTypes {
  STEP_COMPLETED = 'STEP_COMPLETED',
  MESSAGE_GENERATED = 'MESSAGE_GENERATED',
  TASK_COMPLETED = 'TASK_COMPLETED'
}

export type StepCompletedEvent = {
  taskId: string;
  message: string;
};

export type MessageGeneratedEvent = {
  message: string;
};

export type ExecutionCompletedEvent = {
  results: EventResults[];
  message: string;
};

export type ExecutionDataEvent =
  | StepCompletedEvent
  | MessageGeneratedEvent
  | ExecutionCompletedEvent;
