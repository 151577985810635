import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, InputTextModule],
  template: `
    <span class="p-input-icon-left w-full rk-search">
      <i class="pi pi-search"></i>
      <input
        class="rk-body--normal w-full"
        type="text"
        pInputText
        [placeholder]="title"
        [value]="searchValue"
        (keyup.enter)="onSearch($event)"
      />
    </span>
    <span
      *ngIf="hasError"
      class="rk-text--invalid"
      appTextContentAtm
      size="small"
      >Campo incorrecto</span
    >
  `,
  styles: []
})
export class SearchComponent {
  @Input() title = '';
  @Input() searchValue = '';

  @Output() searchEnter = new EventEmitter<string>();
  @Output() searchType = new EventEmitter<string>();
  public hasError = false;

  onSearch(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    if (!/^[a-zA-Z0-9\-\\.\s]*$/.test(value)) {
      this.hasError = true;
      event.preventDefault();
    } else {
      this.hasError = false;
      this.searchEnter.emit(value);
    }
  }
}
