import { Observable, map } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { TaskRepository } from '@domain/models/task/gateway/task-repository';
import { Task } from '@domain/models/task/task';

export class GetLastAddedTasksUseCase implements UseCase<number, Array<Task>> {
  constructor(private _taskRepository: TaskRepository) {}

  execute(count: number, viewDisabled = false): Observable<Array<Task>> {
    return this._taskRepository.getLastAdded(count).pipe(
      map((tasks) => {
        if (viewDisabled) {
          return tasks;
        }
        return tasks.filter((task) => !task.disabled);
      })
    );
  }
}
