import { Observable } from 'rxjs';
import { Archetype, Lego } from '@domain/models/task/task';
import { Cart } from '../cart';

export abstract class CartRepository {
  abstract get(): Observable<Cart | null>;
  abstract getTasks(): Observable<Lego[]>;
  abstract addTask(task: Lego | Archetype): Observable<Cart>;
  abstract removeTask(taskId: string): Observable<string>;
  abstract clear(): Observable<boolean>;
  abstract create(): Observable<boolean>;
}
