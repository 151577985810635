import { Observable, delay, of } from 'rxjs';
import { Execution } from '@domain/models/execution/execution';
import {
  ExecutionType,
  ExecutionRequest,
  ExecutionFactory,
  ExecutionInput,
  ExecutionInputParam,
  InputType,
  InputValidation
} from '@domain/models/execution/execution-request';
import {
  ExecutionResponse,
  ExecutionResponseFactory
} from '@domain/models/execution/execution-response';
import { ExecutionRepository } from '@domain/models/execution/gateway/execution-repository';

export class ExecutionRepositoryFakeImpl extends ExecutionRepository {
  private DELAY_TIME_BASE = 3000;

  getInputs(ids: string[], type: ExecutionType): Observable<ExecutionRequest> {
    const name =
      type === ExecutionType.Workflow
        ? 'Workflow arquitectura transaccional # ' + ids.join(', ')
        : ' ';

    const steps =
      type === ExecutionType.Workflow
        ? this.generateTaskSteps(4)
        : this.generateTaskSteps(1);

    return of(ExecutionFactory.create(type, name, steps)).pipe(
      delay(this.DELAY_TIME_BASE * 2)
    );
  }

  execute(execution: Execution): Observable<ExecutionResponse> {
    const listenerId = execution.refId.length > 0 ? execution.refId : '123';
    return of(
      ExecutionResponseFactory.createExecutionResponse(`/${listenerId}`)
    ).pipe(delay(this.DELAY_TIME_BASE));
  }

  private generateTaskSteps(count: number): ExecutionInput[] {
    const steps = [];
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    for (let i = 0; i < count; i++) {
      crypto.getRandomValues(array);

      const randOneToFive = (array[0] % 5) + 1;
      steps.push({
        taskId: `lego-${i}`,
        name: `Lego ${i}`,
        description: `Lego ${i} description`,
        order: i + 1,
        inputParams: this.generateTaskInputs(randOneToFive)
      });
    }

    return steps;
  }

  private generateTaskInputs(count: number): ExecutionInputParam[] {
    const inputs = [];
    for (let i = 0; i < count; i++) {
      inputs.push({
        inputId: `input-${i}`,
        label: `Input ${i}`,
        description: `Input ${i} description`,
        defaultValue: '',
        type: this.getRandomInputType(),
        options: [],
        validations: this.getRandomValidations()
      });
    }
    return inputs;
  }

  private getRandomInputType(): InputType {
    const types = [
      InputType.Text,
      InputType.List,
      InputType.Check,
      InputType.number,
      InputType.area
    ];
    return types[0];
  }

  private getRandomValidations(): InputValidation[] {
    const validations = [];
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);

    const count = array[0] % 3;

    for (let i = 0; i < count; i++) {
      validations.push(this.getRandomValidation());
    }
    return validations;
  }

  private getRandomValidation(): InputValidation {
    const validations = [
      this.getNotSpecialCharsValidation(),
      this.getMinLengthValidation(),
      this.getMaxLengthValidation(),
      this.getCidrValidation(),
      this.getRequiredValidation()
    ];

    const crypto = window.crypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);

    const randomIndex = array[0] % validations.length;
    return validations[randomIndex];
  }

  private getNotSpecialCharsValidation(): InputValidation {
    const expReg = '^[a-zA-Z0-9]+';
    const errorMessage = 'No se admite caracteres especiales';
    return { expReg, errorMessage };
  }

  private getMinLengthValidation(): InputValidation {
    const expReg = '^.{3,}';
    const errorMessage = 'Mínimo 3 caracteres';
    return { expReg, errorMessage };
  }

  private getMaxLengthValidation(): InputValidation {
    const expReg = '^.{0,10}';
    const errorMessage = 'Máximo 10 caracteres';
    return { expReg, errorMessage };
  }

  private getCidrValidation(): InputValidation {
    const expReg = '^(?:[0-9]{1,3}\\.){3}[0-9]{1,3}\\/[0-9]{1,2}$';
    const errorMessage = 'Debe seguir Formato CIDR';
    return { expReg, errorMessage };
  }

  private getRequiredValidation(): InputValidation {
    const expReg = '^.+$';
    const errorMessage = 'Campo requerido';
    return { expReg, errorMessage };
  }
}
