import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconDirective } from '@view/shared/atoms/icons';

@Component({
  selector: 'app-list-item-mol',
  standalone: true,
  imports: [CommonModule, IconDirective],
  template: `
    <div class="flex">
      <div class="flex-1">
        <i *ngIf="iconId" appIcon [icon]="iconId" class="mr-1"></i>
        <a
          *ngIf="link"
          appTextContentAtm
          href="#"
          (click)="onDocumentationRedirect($event, link)"
          >{{ text }}</a
        >
        <span *ngIf="!link" appTextContentAtm>{{ text }}</span>
      </div>
      <i
        class="rk-list-item-delete"
        *ngIf="canDelete"
        appIcon
        icon="trash"
        (click)="onDelete()"
      ></i>
    </div>
  `,
  styles: []
})
export class ListItemMolComponent {
  @Input() id = '';
  @Input() link = '';
  @Input() text = '';
  @Input() canDelete = false;
  @Input() documentationRedirect = true;
  @Input() iconId = '';

  @Output() deleted = new EventEmitter<string>();

  onDocumentationRedirect(e: Event, url: string) {
    e.preventDefault();
    if (this.documentationRedirect) {
      window.open(url, '_blank');
    }
  }

  onDelete() {
    this.deleted.emit(this.id);
  }
}
