import { ArchetypeResponse } from '@domain/models/task/task';
import { Observable } from 'rxjs';

export abstract class ArchetypeRepository {
  abstract getAllArchetypesPaginated(
    imit: number,
    offset: number,
    searchValue: string
  ): Observable<ArchetypeResponse>;
}
