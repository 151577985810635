import { Observable } from 'rxjs';
import { LegoRepository } from '@domain/models/lego/gateway/lego-repository';
import { UseCase } from '@domain/base/use-case';

export class DownloadGeneratedLegoUseCase implements UseCase<string, string[]> {
  constructor(private _legoRepository: LegoRepository) {}

  execute(executionId: string): Observable<string[]> {
    return this._legoRepository.downloadGeneratedLego(executionId);
  }
}
