import { Injectable } from '@angular/core';

/**
 * Servicio que facilita la paginación de datos en memoria
 */
@Injectable({
  providedIn: null
})
export class PaginationHelper<T> {
  /**
   * Datos a paginar
   */
  private data: T[] = [];

  /**
   * Establece los datos a paginar
   * @param items Datos a paginar
   */
  setItems(items: T[]) {
    this.data = items;
  }

  /**
   * Obtiene los datos de una página
   * @param page Número de página
   * @param pageSize Tamaño de página
   * @param searchFilter Función para filtrar los datos
   */
  getItems(
    page: number,
    pageSize: number,
    searchFilter: (item: T) => boolean
  ): T[] {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const filteredData = this.data.filter((item) => searchFilter(item));
    const itemsInPage = filteredData.slice(startIndex, endIndex);
    return itemsInPage;
  }
}
