import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Servicio que facilita la navegación entre rutas de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class HttpRouterClient {
  /**
   * Servicio Router de angular
   */
  private readonly router = inject(Router);

  public goToTasks(text: string): void {
    this.router.navigate(['legos'], { state: { searchValue: text } });
  }

  public goToPage(path: string): void {
    this.router.navigate([path]);
  }

  public resetState(url: string): void {
    history.replaceState('', '', url);
  }

  public getCurrentUrl(): string {
    return this.router.url;
  }
}
