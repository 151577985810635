import { UseCase } from '@domain/base/use-case';
import { ArchetypeRepository } from '@domain/models/archetype/gateway/archetype-repository';
import { ArchetypeParams, ArchetypeResponse } from '@domain/models/task/task';
import { Observable, map } from 'rxjs';

export class GetAllArchetypesPaginatedUseCase
  implements UseCase<ArchetypeParams, ArchetypeResponse>
{
  constructor(private _archetypeRepository: ArchetypeRepository) {}

  execute(
    taskParams: ArchetypeParams,
    viewDisabled = false
  ): Observable<ArchetypeResponse> {
    return this._archetypeRepository
      .getAllArchetypesPaginated(
        taskParams.limit,
        taskParams.offset,
        taskParams.searchValue
      )
      .pipe(
        map((response) => {
          const { tasks, total } = response;
          if (viewDisabled) {
            return { tasks, total };
          }
          const filteredArchetypes = tasks.filter((task) => !task.disabled);
          return { tasks: filteredArchetypes, total: total };
        })
      );
  }
}
