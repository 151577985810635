import { Observable } from 'rxjs';
import { UseCase } from '@domain/base/use-case';
import { ExecutionEventGateway } from '@domain/models/execution/gateway/execution-events';
import { ExecutionEvent } from '@domain/models/execution/execution-event';

export class WatchExecutionStatusUseCase
  implements UseCase<string, ExecutionEvent>
{
  constructor(private _executionRepository: ExecutionEventGateway) {}

  execute(id: string): Observable<ExecutionEvent> {
    return this._executionRepository.watchExecutionStatus(id);
  }
}
