import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExecutionInputParam } from '@domain/models/execution/execution-request';
import { TooltipModule } from 'primeng/tooltip';
import { REQUIRED_REGEX } from '@infra/helpers/regex-validator-fn';
import { TextContentAtmDirective } from '@view/shared/atoms/fields/text-content.directive';
import { IconDirective } from '@view/shared/atoms/icons';

@Component({
  selector: 'app-form-list-item-mol',
  standalone: true,
  imports: [
    CommonModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    IconDirective,
    TextContentAtmDirective
  ],
  template: `
    <div class="rk-flex-0-1">
      <i
        appIcon
        class="flex-1"
        icon="question-circle"
        [pTooltip]="input.description"
      ></i>
    </div>
    <div class="rk-form-item" [formGroup]="parentForm">
      <div class="flex">
        <select
          [id]="input.inputId"
          type="text"
          class="p-1 w-full flex-1"
          pInputText
          [formControlName]="input.inputId"
        >
          <option value="" disabled selected>
            {{ input.label }}
          </option>
          <option *ngFor="let option of input.options" [value]="option">
            {{ option }}
          </option>
        </select>
        <span class="rk-flex-0-1 flex align-items-end">
          <span class="rk-validate-flag" *ngIf="isRequired()">*</span>
        </span>
      </div>

      <span
        *ngIf="hasError()"
        class="rk-text--invalid"
        appTextContentAtm
        size="small"
        >{{ getErrorMessage() }}</span
      >
    </div>
  `,
  styles: [
    `
      .rk-form-item {
        flex: 3;
        display: flex;
        flex-direction: column;
        min-width: 300px;
        margin-left: 0.5rem;
      }
    `
  ]
})
export class FormListItemMolComponent {
  @Input() parentForm: FormGroup = {} as FormGroup;
  @Input() input: ExecutionInputParam = {} as ExecutionInputParam;

  getErrorMessage() {
    const control = this.parentForm?.get(this.input.inputId);
    if (control?.errors) {
      const errorKey = Object.keys(control.errors)[0];
      const validationError = this.input.validations.find(
        (v) => v.expReg === errorKey
      );
      return validationError ? validationError.errorMessage : '';
    }
    return '';
  }

  isRequired() {
    return (
      this.input.validations &&
      this.input.validations.some((v) => v.expReg === REQUIRED_REGEX)
    );
  }

  hasError() {
    const formControl = this.parentForm.get(this.input.inputId);
    if (!formControl) return false;
    return formControl.invalid && formControl.touched;
  }
}
