import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { Lego } from '@domain/models/task/task';
import { LegoRepository } from '@domain/models/lego/gateway/lego-repository';

export class UpdateLegoUseCase implements UseCase<Lego, void> {
  constructor(private _legoRepository: LegoRepository) {}

  execute(lego: Lego): Observable<void> {
    return this._legoRepository.update(lego);
  }
}
