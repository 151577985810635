import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import {
  ExecutionInput,
  ExecutionInputParam,
  ExecutionRequest
} from '@domain/models/execution/execution-request';
import {
  ExecutionInputParamFactory,
  ExecutionTask,
  ExecutionTaskFactory
} from '@domain/models/execution/execution';
import { Archetype, Lego } from '@domain/models/task/task';
import { ExecutionOps } from '@domain/models/execution/operations';
import {
  ExecutionStepIndex,
  ExecutionStepItem,
  ExecutionStepPos
} from '@domain/models/execution/execution-step';
import { WizardService } from '@domain/services/wizard.service';
import { ExecutionContractService } from '@domain/models/execution/execution-contract-service';
import { StepperComponent } from '@view/executions/organisms/stepper/stepper.component';
import { ParameterizerTaskDescriptionOrgComponent } from '@view/executions/organisms/parameterizer-task-description/parameterizer-task-description.component';
import { ParameterizerTaskLinksOrgComponent } from '@view/executions/organisms/parameterizer-task-links/parameterizer-task-links.component';
import {
  ParameterizerFlowControlMolComponent,
  FlowControlLabels
} from '@view/executions/molecules/parameterizer-flow-control/parameterizer-flow-control.component';
import {
  DynamicFormOrgComponent,
  FormStatus
} from '@view/shared/organisms/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-parameterizer',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DividerModule,
    StepperComponent,
    ParameterizerFlowControlMolComponent,
    DynamicFormOrgComponent,
    ParameterizerTaskDescriptionOrgComponent,
    ParameterizerTaskLinksOrgComponent
  ],
  template: `
    <app-stepper
      #stepper
      *ngIf="steps.length > 0"
      [items]="steps"
      (indexChange)="onStepIndexChanged($event)"
    ></app-stepper>
    <div class="flex mt-4">
      <app-dynamic-form-org
        #form
        *ngIf="currentExecution.taskId"
        class="flex-1"
        [taskId]="currentExecution.taskId"
        [items]="currentInput"
        (statusChange)="onFormStatusChanged($event)"
        (formSubmit)="onFormSubmit($event)"
      ></app-dynamic-form-org>
      <div class="mx-4"></div>
      <div class="flex flex-column flex-1">
        <app-parameterizer-task-description-org
          *ngIf="currentPreviewTask && currentPreviewTask.id"
          [task]="currentPreviewTask"
        ></app-parameterizer-task-description-org>
        <app-parameterizer-task-links-org
          *ngIf="
            currentPreviewTask &&
            currentPreviewTask.usefullinks &&
            currentPreviewTask.usefullinks.length > 0
          "
          class="mt-3"
          [links]="currentPreviewTask.usefullinks"
        ></app-parameterizer-task-links-org>
      </div>
    </div>
    <p-divider styleClass="m-2"></p-divider>
    <app-parameterizer-flow-control-mol
      *ngIf="steps.length > 0"
      class="flex justify-content-between"
      [currentStepIndex]="currentStepIndex"
      [currentFormState]="currentFormState"
      [numberOfSteps]="steps.length"
      (next)="onNextButtonClick($event)"
      (back)="onBackButtonClick()"
    ></app-parameterizer-flow-control-mol>
  `,
  styles: []
})
export class ParameterizerComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper?: StepperComponent;
  @ViewChild('form') form?: DynamicFormOrgComponent;

  @Output() completed = new EventEmitter<ExecutionTask[]>();

  private readonly wizardService = inject(WizardService);
  private readonly executionContractService = inject(ExecutionContractService);

  private destroy$ = new Subject<void>();
  private completedTasks: ExecutionTask[] = [];
  private previewTasks: (Lego | Archetype)[] = [];
  protected steps: ExecutionStepItem[] = [];
  protected contract: ExecutionRequest = {} as ExecutionRequest;
  protected currentInput = {} as ExecutionInputParam[];
  protected currentExecution = {} as ExecutionInput;
  protected currentPreviewTask = {} as Lego | Archetype;
  protected currentFormState = FormStatus.INVALID;
  protected currentStepIndex: ExecutionStepIndex = {
    index: 0,
    state: ExecutionStepPos.First
  };

  ngOnInit(): void {
    this.wizardService.notifyActionLoading();
    this.steps = [];

    this.wizardService
      .getPreviewTasks()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tasks) => {
        this.previewTasks = tasks;
      });

    const contract$ = this.executionContractService.getExecutionContract();
    if (contract$) {
      contract$
        .pipe(takeUntil(this.destroy$))
        .subscribe((contract: ExecutionRequest) => {
          if (!contract.steps) return;
          this.contract = contract;
          this.steps = contract.steps.map((step) => {
            return {
              id: step.taskId,
              label: step.name,
              routerLink: '/home'
            };
          });
          this.currentStepIndex = ExecutionOps.getCurrentStep(contract);
          this.currentInput = contract.steps[0].inputParams;
          this.currentExecution = contract.steps[0];
          this.currentPreviewTask = this.previewTasks[0];
          this.stepper?.reset();
          this.wizardService.notifyActionLoaded();
        });
    }

    this.wizardService
      .watchRestartExecution()
      .pipe(takeUntil(this.destroy$))
      .subscribe((restart) => {
        if (restart) {
          this.completedTasks = [];
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNextButtonClick(flowAction: FlowControlLabels) {
    if (this.currentFormState !== FormStatus.VALID) {
      return;
    }

    this.form?.submit();
    this.stepper?.next();

    if (flowAction === FlowControlLabels.Finish) {
      this.completed.emit(this.completedTasks);
    }
  }

  onBackButtonClick() {
    this.stepper?.back();
  }

  onFormStatusChanged(status: FormStatus) {
    this.currentFormState = status;
  }

  onFormSubmit(form: any) {
    const executionInputParams = ExecutionInputParamFactory.fromObject(form);
    const executionTask = ExecutionTaskFactory.create(
      this.currentExecution.taskId,
      executionInputParams
    );

    const currentSavedTaskIndex = this.completedTasks.findIndex(
      (executionTask) => executionTask.id === this.currentExecution.taskId
    );
    if (currentSavedTaskIndex > -1) {
      this.completedTasks[currentSavedTaskIndex] = executionTask;
    } else {
      this.completedTasks.push(executionTask);
    }
  }

  onStepIndexChanged(indexInfo: ExecutionStepIndex) {
    this.currentStepIndex = indexInfo;
    this.currentInput = this.contract.steps[indexInfo.index].inputParams;
    this.currentExecution = this.contract.steps[indexInfo.index];
    this.currentPreviewTask = this.previewTasks[indexInfo.index];
  }
}
