import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {
  showSuccess(title: string, text: string) {
    Swal.fire({
      icon: 'success',
      title: title,
      text: text
    });
  }

  showInfo(title: string, text: string, confirm: { (): void; (): void }) {
    Swal.fire({
      icon: 'warning',
      iconColor: '#E3E829',
      title: title,
      text: text,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#0033a0',
      cancelButtonColor: '#888B8D'
    }).then((result) => {
      if (result.isConfirmed) {
        confirm();
      }
    });
  }

  showError(title: string, descriptionError: string) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: descriptionError,
      confirmButtonColor: '#0033a0',
      customClass: {
        popup: 'my-sweet-alert'
      }
    });
  }
}
