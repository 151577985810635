import { Injectable } from '@angular/core';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class WebSocketProviderService {
  create<T>(url: string): WebSocketSubject<T> {
    return webSocket<T>(url);
  }

  close(socket: WebSocketSubject<any>): void {
    socket.complete();
  }
}
