export type ExecutionStepItem = {
  id: string;
  label: string;
  routerLink: string;
};

export type ExecutionStepIndex = {
  index: number;
  state: ExecutionStepPos;
};

export enum ExecutionStepPos {
  First = 'first',
  Last = 'last',
  Middle = 'middle'
}
