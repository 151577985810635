import { Observable, map } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { TaskRepository } from '@domain/models/task/gateway/task-repository';
import { TaskState, Task } from '@domain/models/task/task';

export class GetAllTasksUseCase implements UseCase<TaskState, Array<Task>> {
  constructor(private _taskRepository: TaskRepository) {}

  execute(state: TaskState, viewDisabled = false): Observable<Array<Task>> {
    return this._taskRepository.getAll(state).pipe(
      map((tasks) => {
        if (viewDisabled) {
          return tasks;
        }
        return tasks.filter((task) => !task.disabled);
      })
    );
  }
}
