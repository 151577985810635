import { Injectable } from '@angular/core';
import { ExecutionEvent } from '@domain/models/execution/execution-event';
import { StepCompletedHandle } from './strategies/step-completed-handle';
import { MessageGenerateHandle } from './strategies/message-generated-handle';
import { ExecutionCompletedHandle } from './strategies/execution-completed-handle';
import { ProgressArgs } from './progress.component';

@Injectable({
  providedIn: 'root'
})
export class EventHandlerService {
  handleEvent(event: ExecutionEvent, args: Map<ProgressArgs, any>) {
    switch (event.type) {
      case 'TASK_COMPLETED':
        return this.handleStepCompleted(event, args);
      case 'MESSAGE_GENERATED':
        return this.handleMessageGenerated(event, args);
      case 'EXECUTION_COMPLETED':
        return this.handleExecutionCompleted(event, args);
    }
  }

  private handleStepCompleted(
    event: ExecutionEvent,
    args: Map<ProgressArgs, any>
  ) {
    const stepHandle = new StepCompletedHandle();
    stepHandle.handle(event, args);
  }

  private handleMessageGenerated(
    event: ExecutionEvent,
    args: Map<ProgressArgs, any>
  ) {
    const messageHandle = new MessageGenerateHandle();
    messageHandle.handle(event, args);
  }

  private handleExecutionCompleted(
    event: ExecutionEvent,
    args: Map<ProgressArgs, any>
  ) {
    const taskHandle = new ExecutionCompletedHandle();
    taskHandle.handle(event, args);
  }
}
