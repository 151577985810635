import { inject } from '@angular/core';
import { ArchetypeRepository } from '@domain/models/archetype/gateway/archetype-repository';
import { ArchetypeResponse } from '@domain/models/task/task';
import { AppHttpClient } from '@infra/helpers/http-client-service';
import { Observable } from 'rxjs';

export class ArchetypeRepositoryImpl extends ArchetypeRepository {
  private readonly httpClient = inject(AppHttpClient);

  getAllArchetypesPaginated(
    limit: number,
    offset: number,
    searchValue: string
  ): Observable<ArchetypeResponse> {
    const limitParam = limit < 0 ? '?limit=1' : `?limit=${limit}`;
    const offsetParam = offset < 0 ? '' : `&offset=${offset}`;
    const searchParam = searchValue ? `&searchValue=${searchValue}` : '';

    return this.httpClient.get<ArchetypeResponse>(
      `archetypes${limitParam}${offsetParam}${searchParam}`
    );
  }
}
