import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { Task } from '@domain/models/task/task';
import { TextContentAtmDirective } from '@view/shared/atoms/fields/text-content.directive';
import { TextTileAtmDirective } from '@view/shared/atoms/fields/text-title.directive';

@Component({
  selector: 'app-parameterizer-task-description-org',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TextTileAtmDirective,
    TextContentAtmDirective
  ],
  template: `
    <h4 class="m-0" appTextTitleAtm size="h4">Lego: {{ task.name }}</h4>
    <p appTextContentAtm>{{ task.description }}</p>
    <p-button
      *ngIf="task.documentationURL"
      class="rk-button--primary"
      (click)="onDocumentationRedirect(task.documentationURL)"
      label="Ver documentación"
    ></p-button>
  `,
  styles: []
})
export class ParameterizerTaskDescriptionOrgComponent {
  @Input() task: Task = {} as Task;

  onDocumentationRedirect(url: string) {
    window.open(url, '_blank');
  }
}
