import { Execution, ExecutionTask, ExecutionProject } from './execution';

export class ExecutionBuilder {
  private readonly execution: Execution = {
    refId: '',
    project: {
      projectName: '',
      applicationName: '',
      apmId: ''
    },
    tasks: []
  };

  withRefId(refId: string) {
    this.execution.refId = refId;
    return this;
  }

  withProject(project: ExecutionProject) {
    this.execution.project = project;
    return this;
  }

  withTasks(tasks: ExecutionTask[]) {
    this.execution.tasks = tasks;
    return this;
  }

  build() {
    return this.execution;
  }
}
