import { Observable } from 'rxjs';
import { UseCase } from '@domain/base/use-case';
import {
  ExecutionRequest,
  ExecutionType
} from '@domain/models/execution/execution-request';
import { ExecutionRepository } from '@domain/models/execution/gateway/execution-repository';

export class GetExecutionContractUseCase
  implements UseCase<{ type: ExecutionType; ids: string[] }, ExecutionRequest>
{
  constructor(private _executionRepository: ExecutionRepository) {}

  execute({
    type,
    ids
  }: {
    type: ExecutionType;
    ids: string[];
  }): Observable<ExecutionRequest> {
    return this._executionRepository.getInputs(ids, type);
  }
}
