import { inject } from '@angular/core';
import { LegoRepository } from '@domain/models/lego/gateway/lego-repository';
import { LegoGeneratedResponse } from '@domain/models/lego/lego-generated';
import { AppHttpClient } from '@infra/helpers/http-client-service';
import { Observable } from 'rxjs';
import { Task } from '@domain/models/task/task';
import { LegoExecutionResponse } from '@domain/models/lego/lego-execution';

export class LegoRepositoryImpl extends LegoRepository {
  private readonly httpClient = inject(AppHttpClient);

  getLegoGeneratedPaginated(
    limit: number,
    offset: number,
    searchValue: string
  ): Observable<LegoGeneratedResponse> {
    const limitParam = limit < 0 ? '?limit=1' : `?limit=${limit}`;
    const offsetParam = offset < 0 ? '' : `&offset=${offset}`;
    const searchParam = searchValue ? `&searchValue=${searchValue}` : '';

    return this.httpClient.get<LegoGeneratedResponse>(
      `legos/generated${limitParam}${offsetParam}${searchParam}`
    );
  }

  downloadGeneratedLego(executionId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`legos/download/${executionId}`);
  }

  save(lego: Task): Observable<void> {
    return this.httpClient.post<void>('legos', lego);
  }

  update(lego: Task): Observable<void> {
    return this.httpClient.put<void>(`legos/${lego.id}`, lego);
  }

  getLegosExecution(
    limit: number,
    offset: number,
    stateExecution: string,
    beginDate: string,
    endDate: string
  ): Observable<LegoExecutionResponse> {
    const params = [
      limit >= 0 ? `limit=${limit}` : 'limit=1',
      offset >= 0 ? `offset=${offset}` : '',
      stateExecution ? `stateExecution=${stateExecution}` : '',
      beginDate ? `beginDate=${beginDate}` : '',
      endDate ? `endDate=${endDate}` : ''
    ]
      .filter(Boolean)
      .join('&');

    return this.httpClient.get<LegoExecutionResponse>(
      `report/legos/execution?${params}`
    );
  }
}
