import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Archetype,
  ArchetypeResponse,
  Lego,
  TaskParams,
  TaskResponse,
  TaskState,
  TaskType
} from '@domain/models/task/task';
import { getTaskDi } from 'src/app/config/di/task-di';
import taskConstants from '@domain/models/task/constants';
import { RouterModule } from '@angular/router';
import { HttpRouterClient } from '@infra/helpers/http-router-service';
import { getExecutionDi } from 'src/app/config/di/exec-di';
import { CartService } from '@domain/services/cart.service';
import { getCartDi } from 'src/app/config/di/cart-di';
import { ExecutionContractFactory } from '@domain/models/execution/execution';
import { WizardVisualizerService } from '@view/executions/pages/wizard/wizard-visualizer.service';
import { WizardComponent } from '@view/executions/pages/wizard/wizard.component';
import { SearchComponent } from '@view/shared/atoms/search/search.component';
import { TaskListContainerComponent } from '@view/tasks/organisms/task-list-container/task-list-container.component';
import { GetAllTasksPaginatedUseCase } from '@domain/usecases/task/get-all-tasks-paginated-use-case';
import { getArchetypeDi } from 'src/app/config/di/archetype-di';
import { GetAllArchetypesPaginatedUseCase } from '@domain/usecases/archetype/get-all-archetypes-paginated-use-case';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-task-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SearchComponent,
    TaskListContainerComponent,
    WizardComponent
  ],
  providers: [getTaskDi, getArchetypeDi, getExecutionDi, getCartDi],
  template: `
    <div class="flex flex-column align-items-center">
      <h1 class="rk-text-color--primary">
        {{ isLegos() ? 'Legos' : 'Arquetipos' }}
      </h1>
      <app-search
        class="w-full md:w-6"
        title="Busca un generador por nombre o etiqueta..."
        (searchEnter)="onSearch($event)"
        [searchValue]="searchValueParam"
      ></app-search>
      <div *ngIf="isLegos()" class="flex justify-content-end w-full pr-8 mr-8">
        <a [routerLink]="linkGenerated" appLink>
          {{ textLinkGenerated }}
        </a>
      </div>
      <app-task-list-container
        [tasks]="tasks"
        [totalTaskNumber]="totalTaskNumber"
        [searchValue]="searchValueParam"
        [isEmptySearch]="isEmptySearch"
        (taskSelected)="onTaskSelected($event)"
      ></app-task-list-container>
    </div>
    <app-wizard #wizard></app-wizard>
  `,
  styles: []
})
export class TaskListComponent implements OnInit, AfterViewInit {
  @ViewChild('wizard') wizard?: WizardComponent;

  private readonly getAllTasksPaginatedUseCase = inject(
    GetAllTasksPaginatedUseCase
  );
  private readonly getAllArchetypesPaginatedUseCase = inject(
    GetAllArchetypesPaginatedUseCase
  );
  private readonly cartService = inject(CartService);
  private readonly appRouter = inject(HttpRouterClient);
  private readonly wizardVisualizerService = inject(WizardVisualizerService);

  protected taskTypeOptions = taskConstants.TYPE_OPTIONS;
  protected tasks: Lego[] | Archetype[] = [];
  protected totalTaskNumber = 0;
  protected searchValueParam = '';
  protected isEmptySearch = { value: false };
  protected linkGenerated = '/legos/generated';
  protected textLinkGenerated = 'Mis legos generados';
  protected rute = '';

  ngOnInit(): void {
    this.rute = this.appRouter.getCurrentUrl();
    this.searchValueParam = history.state
      ? history.state.searchValue || ''
      : '';
    this.getTasksPaginated(this.searchValueParam);
  }

  ngAfterViewInit(): void {
    this.wizardVisualizerService.setComponent(this.wizard);
  }

  onOptionSelected(taskType: TaskType) {
    if (TaskType.Lego === taskType) {
      this.getTasksPaginated(this.searchValueParam);
      this.textLinkGenerated = 'Mis legos generados';
      this.linkGenerated = '/legos/generated';
    } else if (TaskType.Workflow !== taskType) {
      this.getTasksPaginated('');
      this.textLinkGenerated = 'Mis Workflow generados';
      this.linkGenerated = '/Workflow/generated';
    }
  }

  onTaskSelected(task: Lego) {
    if (task.type === TaskType.Workflow) {
      const contractReq = ExecutionContractFactory.create([task]);
      this.wizardVisualizerService.setTitle(task.name);
      this.wizardVisualizerService.open(contractReq, [task]);
    } else {
      this.cartService.addTaskToCart(task);
    }
  }

  private getTasksPaginated(searchValue: string) {
    const useCase: {
      execute: (
        params: TaskParams
      ) => Observable<TaskResponse | ArchetypeResponse>;
    } = this.isLegos()
      ? this.getAllTasksPaginatedUseCase
      : this.getAllArchetypesPaginatedUseCase;

    const params: TaskParams = {
      limit: 12,
      offset: 0,
      searchValue,
      state: TaskState.All
    };

    if (this.isLegos()) {
      params.state = TaskState.Active;
    }

    useCase
      .execute(params)
      .subscribe((response: { total: number; tasks: Lego[] | Archetype[] }) => {
        this.totalTaskNumber = response.total;
        this.tasks = response.tasks;
      });
  }

  onSearch(searchValue: string) {
    this.searchValueParam = searchValue;
    this.getTasksPaginated(searchValue);
    this.isEmptySearch = { value: searchValue === '' };
  }

  isLegos() {
    return this.rute === '/legos';
  }
}
