import { UseCase } from '@domain/base/use-case';
import { ExecutionDownloaderProvider } from '@domain/models/execution/gateway/execution-downloader';

export class GetResultsFileUseCase implements UseCase<string[], boolean> {
  constructor(
    private _executionDownloaderProvider: ExecutionDownloaderProvider
  ) {}

  execute(infoFile: string[]) {
    return this._executionDownloaderProvider.downloadFile(infoFile);
  }
}
