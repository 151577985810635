import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { Archetype, Lego, TaskType } from '@domain/models/task/task';
import { PanelModule } from 'primeng/panel';
import { ChipModule } from 'primeng/chip';

@Component({
  selector: 'app-task[task]',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TagModule,
    ButtonModule,
    PanelModule,
    ChipModule
  ],
  template: `
    <p-card
      *ngIf="task"
      class="rk-task-card"
      [header]="task.name"
      [subheader]="isArchetype(task) ? task.description : ''"
    >
      <ng-template pTemplate="content">
        <p *ngIf="!isArchetype(task)" class="m-0">{{ task.description }}</p>
        <div *ngIf="isArchetype(task)">
          <p class="rk-headline--sub-one rk-text-color--primary">
            Legos incluidos
          </p>
          <p-chip
            *ngFor="let lego of task.legos"
            [label]="lego.name"
            class="mr-2"
          ></p-chip>
        </div>
      </ng-template>
      <ng-template *ngIf="!isArchetype(task)" pTemplate="footer">
        <div class="flex justify-content-between">
          <div class="flex align-items-center">
            <p-tag
              styleClass="mr-2"
              icon="pi pi-tag"
              [value]="getLegoTags(task.tags)"
            ></p-tag>
          </div>

          <p-button
            [icon]="'pi ' + getIconName()"
            [styleClass]="getStyledClass()"
            (click)="onTaskClick()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  `,
  styles: []
})
export class TaskComponent {
  @Input() task?: any;
  @Output() taskClicked = new EventEmitter<Lego>();

  protected getIconName(): string {
    if (this.task.selected) {
      return 'pi-times';
    }

    return this.task.type === TaskType.Workflow ? 'pi-wrench' : 'pi-plus';
  }

  protected getStyledClass(): string {
    const classes = `p-button-rounded rk-lego-action--${this.task.type.toLowerCase()}`;

    return this.task.selected ? `${classes} rk-lego--selected` : classes;
  }

  isArchetype(task: Lego | Archetype): boolean {
    return task.type === TaskType.Archetype;
  }

  onTaskClick() {
    this.taskClicked.emit(this.task);
  }

  protected getLegoTags(tags: string): string {
    return tags.replace('_', ' ');
  }
}
