import { Directive, HostBinding, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appTextTitleAtm]',
  standalone: true
})
export class TextTileAtmDirective implements OnChanges {
  @Input() color: 'primary' | 'secondary' | 'none' = 'none';
  @Input() size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' = 'h5';

  @HostBinding('class')
  elementClass = this.setClassValue();

  ngOnChanges() {
    this.elementClass = this.setClassValue();
  }

  setClassValue() {
    const defaultClassValue = `rk-headline--${this.size}`;

    return this.color !== 'none'
      ? `${defaultClassValue} rk-text--${this.color}`
      : defaultClassValue;
  }
}
