import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-progress-spinner',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule],
  template: `
    <p-progressSpinner
      [styleClass]="getStyleClass()"
      strokeWidth="8"
      fill="#ffffff"
      animationDuration=".5s"
    ></p-progressSpinner>
  `,
  styles: []
})
export class ProgressSpinnerComponent {
  @Input() width = '4rem';
  @Input() height = '4rem';

  getStyleClass() {
    return `w-${this.width} h-${this.height}`;
  }
}
