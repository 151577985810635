import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ExecutionInputParam,
  InputType,
  InputValidation
} from '@domain/models/execution/execution-request';
import { regexValidatorFn } from '@infra/helpers/regex-validator-fn';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {
  private compiledRegex = new Map<string, RegExp>();

  toFormGroup(items: ExecutionInputParam[], snapshot: any) {
    const group: any = {};

    items.forEach((item) => {
      const controlValue =
        item.type === InputType.Check
          ? false
          : snapshot[item.inputId] || item.defaultValue || '';
      group[item.inputId] = new FormControl(
        controlValue,
        Validators.compose(this.generateValidations(item.validations))
      );
    });

    return new FormGroup(group);
  }

  private generateValidations(validations: InputValidation[]) {
    if (!validations || validations.length === 0) {
      return [];
    }

    return validations.map((validation) => {
      const regex =
        this.compiledRegex.get(validation.expReg) ||
        new RegExp(validation.expReg);
      this.compiledRegex.set(validation.expReg, regex);
      return regexValidatorFn(regex, { [validation.expReg]: true });
    });
  }
}
