import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskLinks } from '@domain/models/task/task';
import { TextContentAtmDirective } from '@view/shared/atoms/fields/text-content.directive';
import { ListItemMolComponent } from '@view/shared/molecules/list-item/list-item.component';

@Component({
  selector: 'app-parameterizer-task-links-org',
  standalone: true,
  imports: [CommonModule, ListItemMolComponent, TextContentAtmDirective],
  template: `
    <span class="block mb-3" appTextContentAtm>Enlaces útiles:</span>
    <ul class="list-none p-0 m-0">
      <li class="flex justify-content-between mb-3" *ngFor="let link of links">
        <app-list-item-mol
          iconId="paperclip"
          [text]="link.name"
          [link]="link.url"
          class="w-full"
        >
        </app-list-item-mol>
      </li>
    </ul>
  `,
  styles: []
})
export class ParameterizerTaskLinksOrgComponent {
  @Input() links: TaskLinks[] = {} as TaskLinks[];
}
