import { Observable, map } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { TaskRepository } from '@domain/models/task/gateway/task-repository';
import { TaskParams, TaskResponse } from '@domain/models/task/task';

export class GetAllTasksPaginatedUseCase
  implements UseCase<TaskParams, TaskResponse>
{
  constructor(private _taskRepository: TaskRepository) {}

  execute(
    taskParams: TaskParams,
    viewDisabled = false
  ): Observable<TaskResponse> {
    return this._taskRepository
      .getAllPaginated(
        taskParams.limit,
        taskParams.offset,
        taskParams.state,
        taskParams.searchValue
      )
      .pipe(
        map((response) => {
          const { tasks, total } = response;
          if (viewDisabled) {
            return { tasks, total };
          }
          const filteredTasks = tasks.filter((task) => !task.disabled);
          return { tasks: filteredTasks, total: total };
        })
      );
  }
}
