import { LegoRepository } from '@domain/models/lego/gateway/lego-repository';
import { CreateLegoUseCase } from '@domain/usecases/lego/create-lego-use-case';
import { DownloadGeneratedLegoUseCase } from '@domain/usecases/lego/download-generated-lego-use-case';
import { GetLegosExecutionUseCase } from '@domain/usecases/lego/get-legos-execution-use-case';
import { GetLegosGeneratedPaginatedUseCase } from '@domain/usecases/lego/get-legos-generated-paginated-use-case';
import { UpdateLegoUseCase } from '@domain/usecases/lego/update-lego-use-case';
import { LegoRepositoryImpl } from '@infra/lego/lego-repository-impl';

const getLegoRepositoryProvider = {
  provide: LegoRepository,
  useClass: LegoRepositoryImpl
};

const getLegoGeneratedPaginatedUseCase = (legoRepository: LegoRepository) => {
  return new GetLegosGeneratedPaginatedUseCase(legoRepository);
};

const downloadGeneratedLegoUseCase = (legoRepository: LegoRepository) => {
  return new DownloadGeneratedLegoUseCase(legoRepository);
};

const createLegoUseCaseFactory = (legoRepository: LegoRepository) => {
  return new CreateLegoUseCase(legoRepository);
};

const updateLegoUseCaseFactory = (legoRepository: LegoRepository) => {
  return new UpdateLegoUseCase(legoRepository);
};

const getLegosExecutionUseCaseFactory = (legoRepository: LegoRepository) => {
  return new GetLegosExecutionUseCase(legoRepository);
};

const getLegosGeneratedPaginatedUseCaseProvider = {
  provide: GetLegosGeneratedPaginatedUseCase,
  useFactory: getLegoGeneratedPaginatedUseCase,
  deps: [LegoRepository]
};

const downloadGeneratedLegoUseCaseProvider = {
  provide: DownloadGeneratedLegoUseCase,
  useFactory: downloadGeneratedLegoUseCase,
  deps: [LegoRepository]
};

const createLegoUseCaseProvider = {
  provide: CreateLegoUseCase,
  useFactory: createLegoUseCaseFactory,
  deps: [LegoRepository]
};

const updateLegoUseCaseProvider = {
  provide: UpdateLegoUseCase,
  useFactory: updateLegoUseCaseFactory,
  deps: [LegoRepository]
};

const getLegosExecutionUseCaseProvider = {
  provide: GetLegosExecutionUseCase,
  useFactory: getLegosExecutionUseCaseFactory,
  deps: [LegoRepository]
};

export const getLegoDi = [
  getLegoRepositoryProvider,
  getLegosGeneratedPaginatedUseCaseProvider,
  downloadGeneratedLegoUseCaseProvider,
  createLegoUseCaseProvider,
  updateLegoUseCaseProvider,
  getLegosExecutionUseCaseProvider
];
